import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function CreateNoteForm({createNote}) {
  const [content, setContent] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    createNote({content});
    e.target.reset();
  }

  return(
    <div className="mt-3">
      <h3>Add Note</h3>
      <form onSubmit={handleSubmit} className="form-control">
        <div className="form-floating mb-3">
          <textarea className="form-control" placeholder="Note content" onChange={e => setContent(e.target.value)} />
          <label>Enter note content</label>
        </div>
        <div>
          <button type="submit" className="btn btn-outline-primary">Add Note</button>
        </div>
      </form>
    </div>
  )
}

CreateNoteForm.propTypes = {
  createNote: PropTypes.func.isRequired
};