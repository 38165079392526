import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import useAuth from '../components/useAuth';
import Badge from 'react-bootstrap/Badge';
import CreateProfessionalLicenseForm from '../components/CreateProfessionalLicenseForm';
import Alert from 'react-bootstrap/Alert';
import ProfileForm from '../components/ProfileForm';

export default function Orders() {
  const [showLicenseForm, setShowLicenseForm] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [error, setError] = useState(false);
  let auth = useAuth();
  const [token, setToken] = useState(auth.token);
  let user = auth.user

  useEffect(() => {
    document.title = `Profile for ${user?.first_name} ${user?.last_name}| Divan Medical`;
  });

  if(!user){
    return <div>...couldn't load user</div>
  }

  // edit profile
  const updateDoctorRequest = (updateData) => {
    setError(null)
    let callOptions = {headers: {'Authorization': `Token ${token}`}}
    axios.put(`${process.env.REACT_APP_API_URL}/doctors/${user.id}`, {"doctor": updateData}, callOptions)
      .then(response => {auth.setUser(response.data); setShowLicenseForm(false); setEditProfile(false)})
      .catch(error => setError(error.message) )
  }
  
  const createProfessionalLicense = (values) =>{
    let newLicense = {
      code: values.code, 
      expiration_date: values.expirationDate, 
      issue_date: values.issueDate, 
      type_license: values.licenseType,
      state_id: values.stateId
    }
    updateDoctorRequest({professional_licenses_attributes: [newLicense]});
  }
  
  const destroyProfessionalLicense = (licenseId) =>{
    console.log("on destroyProfessionalLicense: ", licenseId)
    let removeLic = {
      id: licenseId,
      _destroy: true
    }
    updateDoctorRequest({professional_licenses_attributes: [removeLic]});
  }

  return(
    <div className="row">
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="col">
        {!editProfile && <>
            <h1>Your Information</h1>
            <div>
              <span onClick={()=> setEditProfile(true)} className="pointer">Edit</span>
              <h2>{user.first_name} {user.last_name}</h2>
              <p>{user.email}</p>
              <p>{user.mobile_number}</p>
              {user.npi && <p>NPI: {user.npi}</p>}
            </div>
          </>
        }
        
        {editProfile && <>
            <span onClick={()=> setEditProfile(false)} className="pointer">Cancel</span>
            <ProfileForm updateProfile={updateDoctorRequest} user={user} />
          </>
        }
      </div>
      <div className="col">
        {showLicenseForm && 
          <CreateProfessionalLicenseForm 
            createProfessionalLicense={createProfessionalLicense} 
            setShowLicenseForm={setShowLicenseForm} 
          />
        }
        <h2>Your Professional Licenses 
            {!showLicenseForm && <span onClick={() => setShowLicenseForm(true)} className="btn btn-outline-primary ms-2">+</span>}
            {showLicenseForm && <span onClick={() => setShowLicenseForm(false)} className="btn btn-outline-primary ms-2">-</span>}
        </h2>
        {user.professional_licenses.map( item => 
          <div key={item.id+item.code}>
            <p className="text-muted">License for the State of {item.state}</p>
            <p><strong>Code: <span className="capitalize">{item.code}</span></strong> Type: {item.type_license}
            <Badge bg={user.status === 'active' ? 'primary' : 'danger'} pill className="ms-3">
              {user.status}
            </Badge>
            <span className="text-danger ms-2 pointer" onClick={()=>{destroyProfessionalLicense(item.id)}}>x</span></p>
            <p>Issue Date: {item.issue_date} Expiration Date: {item.expiration_date}</p>
            <hr/>
          </div>
        )}
      </div>
    </div>
  );
}