import React, { useState, useEffect, useMemo } from 'react';
import OrderListItem from '../components/OrderListItem'
import axios from 'axios';
import OrderPagination from '../components/OrderPagination';
import { Outlet } from 'react-router-dom';
import useAuth from '../components/useAuth';

export default function Orders() {
  const [orderList, setOrderList] = useState(null);
  const [totalPages, setTotaPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [orderState, setOrderState] = useState(["pending", "second_approval", "declined", "approved", "appointment", "in_review"]);
  const [error, setError] = useState(null);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [filterPlatform, setFilterPlatform] = useState("");
  let auth = useAuth();
  const [token, setToken] = useState(auth.token);

  useEffect(() => {
    document.title = `Orders | Divan Medical`;
  });

  useEffect(() => {
    if(!orderList){
      let callOptions = {headers: {'Authorization': `Token ${token}`}, 
                        params: {per_page: perPage, page: currentPage, aasm_state: orderState, 
                          order_number: searchOrderNumber, patient: searchPatient, platform_domain: filterPlatform}}
      axios.get(`${process.env.REACT_APP_API_URL}/orders`, callOptions)
          .then(response => (setTotaPages(response.headers["x-total-pages"]), setOrderList(response.data)))
          .catch(error => { if(error.response?.status === 401){ sessionStorage.clear() }; setError('Something went wrong') } );
    }
  }, [orderList, currentPage, token, perPage, orderState, searchOrderNumber, searchPatient, filterPlatform]);


  return(
    <div className="row">
      <div className="col">
        <h2>Orders</h2>
        {!orderList && <div>...loading orders</div>}
        {totalPages && <OrderPagination 
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  setCurrentPage={setCurrentPage} 
                  setPerPage={setPerPage} 
                  setOrderList={setOrderList}
                  setOrderState={setOrderState}
                  setFilterPlatform={setFilterPlatform}
                  setError={setError}
              />}
        <div className="row">
          <div className="col-md-4">
            <div className="row g-3">
              <div className="col-auto">
                <label className="visually-hidden">Search for patient</label>
                <input type="text" placeholder="Enter patient name or email" value={searchPatient} className="form-control"
                        onChange={(e) => {setSearchPatient(e.target.value)} } />
              </div>
              <div className="col-auto">
                <button type="submit" className="btn btn-outline-primary mb-3" onClick={()=>setOrderList(null)} >Search</button>
              </div>
            </div>
          </div>
        </div>
        
        
        {orderList && <OrderListItem orderList={orderList} />}
        <Outlet />
      </div>
    </div>
  );
}