import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from './useAuth';

export default function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user && !auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}