import React from 'react';
import { Link } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

export default function OrderListItem({orderList}) {
  const pillColor = (state) =>{
    switch(state) {
      case "declined":
        return "danger"
        break;
      case "approved":
        return "primary"
        break;
      default:
        return "info"
    }
  }

  const orderItems = orderList.map((order) =>
    <Link to={`/orders/${order.order_number}`} key={order.order_number}>
      <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
          key={order.order_number}
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{order.patient.first_name} {order.patient.last_name}</div>
            <p className="text-muted">Order # {order.order_number} - Pending {order.pending_at}<br/>
            Platform: {order.platform}</p>
            {order.first_approval && <strong>First Approval By: Dr. {order.first_approval.last_name}</strong>}
          </div>
          <Badge bg={pillColor(order.aasm_state)} pill>
            {order.aasm_state}
          </Badge>
        </ListGroup.Item>
    </Link>
  );
  return(
    <div className="col-md-8 order-list mb-4">
      <ListGroup as="ol" numbered>
        {orderItems}
      </ListGroup>
    </div>
  );
}