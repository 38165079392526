import React, {useState, useEffect} from 'react';
import axios from 'axios';

export default function PlatformDropdown({setFilterPlatform, setError}) {
  
  const [platforms, setPlatforms] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/platforms`, {})
      .then(response => (setPlatforms(response.data)))
      .catch(error => { if(error.response?.status === 401){ sessionStorage.clear() }; setError('Something went wrong') } );
  }, []);

  const platformItems = platforms && platforms.map( (platform) =>
    <option key={platform.domain} value={platform.domain}>{platform.domain}</option>
  );

  return(
    <div className="col-auto">
      <label>Platform</label><br/>
      <select onChange={(e)=> setFilterPlatform(e.target.value)}>
        <option></option>
        {platformItems}
      </select>
    </div>
  );
}