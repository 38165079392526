import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import useAuth from '../components/useAuth';
import Alert from 'react-bootstrap/Alert';
import OT from '@opentok/client';
import {OTSession, OTPublisher, OTStreams, OTSubscriber} from 'opentok-react';
import useWindowDimensions from '../components/useWindowDimensions';

export default function Orders() {
  const [videoSessionId, setVideoSessionId] = useState(null);
  const [videoToken, setVideoToken] = useState(null);
  const [error, setError] = useState(null);
  const [connection, setConnection] = useState('connecting');
  const [publishVideo, setPublishVideo] = useState(true)
  let auth = useAuth();
  const [token, setToken] = useState(auth.token);
  const videoAPIKey = process.env.REACT_APP_VIDEO_API_KEY;
  let params = useParams();
  let orderNumber = params.order_number;
  let location = useLocation();
  let ot = location.search.split("ot");
  const { width, height } = useWindowDimensions();
  const [subscriberHeight, setSubscriberHeight] = useState(null)
  const [publisherHeight, setPublisherHeight] = useState(null)

  useEffect(() => {
    document.title = `Video Call | Divan Medical`;
  });

  useEffect(() => {
    let callOptions = {headers: {'Authorization': `Token ${token}`}}
    axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderNumber}/video`, callOptions)
      .then(response => {setVideoSessionId(response.data['sessionId']); setVideoToken(response.data['token']); setError(null); } )
      .catch(error => setError(error.message) )
  }, [orderNumber, token]);

  useEffect(() => {
    console.log("windowWidth: ", width)
    if(width <= 430){      
      setSubscriberHeight(300)
      setPublisherHeight(300)
    } else{
      setSubscriberHeight(500)
      setPublisherHeight(350)
    }
  }, [width]);

  const sessionEventHandlers = {
    sessionConnected: () => {
      setConnection('Connected');
    },
    sessionDisconnected: () => {
      setConnection('Disconnected');
    },
    sessionReconnected: () => {
      setConnection('Reconnected');
    },
    sessionReconnecting: () => {
      setConnection('Reconnecting');
    },
  };

  const onPublish = () =>{
    console.log("onPublish!")
  }

  const onSubscribe = () =>{
    console.log("onSubscribe!")
  }

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source');
    },
    streamCreated: () => {
      console.log('Publisher stream created');
    },
    streamDestroyed: ({ reason }) => {
      console.log(`Publisher stream destroyed because: ${reason}`);
    },
  };

  const subscriberEventHandlers = {
    videoEnabled: () => {
      console.log('Subscriber video enabled');
    },
    videoDisabled: () => {
      console.log('Subscriber video disabled');
    },
  };

  return(
    <div className="row">
      <div className="col-12">
        <Link to={`/orders/${orderNumber}`}>Back to Order</Link>
      </div>
      <div className="col">
        {error && <Alert variant="danger">{error}</Alert>}
        <h2>Video Call</h2>
      </div>
      {videoSessionId && <OTSession
          apiKey={videoAPIKey}
          sessionId={videoSessionId}
          token={videoToken}
          onError={setError}
          eventHandlers={sessionEventHandlers}
        >
          <div className="row">
            <div className="col-12">
              <button id="videoButton" onClick={() => setPublishVideo(!publishVideo)} className="btn btn-outline-primary mb-2">
                {publishVideo ? 'Disable' : 'Enable'} Video
              </button>
            </div>
            <div className="col-md-8">
              <OTStreams>
                <OTSubscriber
                  properties={{ width: '100%', height: subscriberHeight }}
                  onSubscribe={onSubscribe}
                  onError={setError}
                  eventHandlers={subscriberEventHandlers}
                />
              </OTStreams>
            </div>
            <div className="col-md-4">
              <OTPublisher
                properties={{ publishVideo, width: '100%', height: publisherHeight }}
                onPublish={onPublish}
                onError={setError}
                eventHandlers={publisherEventHandlers}
              />
            </div>
          </div>          
        </OTSession>}

      
    </div>
  );
}