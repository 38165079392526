import React, { createContext } from 'react';
import useProvideAuth from './useProvideAuth';

export const AuthContext = createContext();

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export default ProvideAuth