import React, { useState } from 'react';
import axios from 'axios';

export default function UploadOrderFile({token, orderId, setOrder, setError}) {
  const [file, setFile] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null)
    const url = `${process.env.REACT_APP_API_URL}/orders/${orderId}/add_attachment`;
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Token ${token}`
      },
    };
    axios.post(url, formData, config).then((response) => {
      setOrder(response.data)
    }).catch((error)=>{
      setError("File was not uploaded. Please try again.")
    });
  }

  return(
    <div className="mt-3">
      <h3>Add Document</h3>
      <form onSubmit={handleSubmit} className="form-control">
        <div className="mb-3">
          <label>Select file to upload</label>
          <input type="file" className="form-control" onChange={e => setFile(e.target.files[0])}/>
        </div>
        <div>
          <button type="submit" className="btn btn-outline-primary">Upload Document</button>
        </div>
      </form>
    </div>
  )
}