import React from 'react';

const answerArrRespones = (answerArr) => {
  const answerItems = answerArr.map( (a) => <li key={a.id}>{a.value}</li>)
  return <ul>{answerItems}</ul>
}

export default function SurveyAnswers({surveyAnswers}) {
  const surveyItems = surveyAnswers.map((answer) =>
    <li key={answer.answer_id}>
      <p><strong>{answer.question}</strong></p>
      <div>{typeof answer.answer == "string" ? answer.answer : answerArrRespones(answer.answer)}</div>
      {answer.justification && <p>{answer.justification}</p>}
    </li>
  );

  return(
    <div>
      <h2>Medical Survey</h2>
      <hr/>
      <ol>
        {surveyItems}
      </ol>
    </div>
    
  );
}