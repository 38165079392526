import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

export default function ProfileForm ({updateProfile, user}) {
  console.log("user: ", user)
  return(
    <div className="my-3">
      <h3>Edit Profile</h3>
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          npi: user.npi,
          mobile_number: user.mobile_number === null ? "" : user.mobile_number
        }}
        onSubmit={(values) => updateProfile(values)}
      >
        <Form>
          <div className="form-floating mb-3">
            <Field id="first_name" name="first_name" className="form-control" />
            <label htmlFor="first_name">First Name</label>
          </div>
          <div className="form-floating mb-3">
            <Field id="last_name" name="last_name" className="form-control" />
            <label htmlFor="last_name">Last Name</label>
          </div>
          <div className="form-floating mb-3">
            <Field id="email" name="email" className="form-control" disabled/>
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-floating mb-3">
            <Field id="mobile_number" name="mobile_number" className="form-control" />
            <label htmlFor="mobile_number">Phone Number</label>
          </div>
          <div className="form-floating mb-3">
            <Field id="npi" name="npi" className="form-control" />
            <label htmlFor="npi">NPI</label>
          </div>
  
          <button type="submit" className="btn btn-outline-primary">Update Profile</button>
        </Form>
      </Formik>
    </div>
  )
};

ProfileForm.propTypes = {
  updateProfile: PropTypes.func.isRequired
};