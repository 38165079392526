import React from 'react';

export default function PatientInfo({address}) {
  return(
    <div>
      <hr/>
      <h2>Address</h2>
      <p>{address.street_one}</p>
      {address.two && <p>{address.two}</p>}
      <p>{address.city}, {address.state} {address.postal_code}</p>
    </div>
    
  );
}