let passphrase = '';

const set = (_passphrase) => {
  passphrase = _passphrase;
};

const get = () => passphrase;

module.exports = {
  set,
  get,
};
