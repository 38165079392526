import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import useAuth from './useAuth'
import mmjLogo from '../img/mmj-white-logo.png'
import {useLocation} from 'react-router-dom'

export default function Navigation() {
  let auth = useAuth();
  let location = useLocation();

  const logOut = () =>{
    auth.signout(() => {
      console.log("logging out")
    })
  }

  const navOptions = auth.user && (
      <Nav className="me-auto" activeKey={location.pathname}>
        <Nav.Link href="/" className={isActive => "nav-link-test" + (isActive ? " active" : "")  }>Orders</Nav.Link>
        <Nav.Link href="/profile" className={isActive => "nav-link-test" + (isActive ? " active" : "")  }>Profile</Nav.Link>
        <Nav.Link href="#" onClick={()=> logOut() }>Log Out</Nav.Link>
      </Nav>
    )

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/orders">
          <img src={mmjLogo} width="110" className="d-inline-block align-top" alt="MMJ Recs" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {navOptions}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}