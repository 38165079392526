export const ALL_STATES = [
  {
    id: 1,
    iso_abbr: "AL",
    name: "Alabama"
  },
  {
    id: 2,
    iso_abbr: "AK",
    name: "Alaska"
  },
  {
    id: 3,
    iso_abbr: "AS",
    name: "American Samoa"
  },
  {
    id: 4,
    iso_abbr: "AZ",
    name: "Arizona"
  },
  {
    id: 5,
    iso_abbr: "AR",
    name: "Arkansas"
  },
  {
    id: 6,
    iso_abbr: "CA",
    name: "California"
  },
  {
    id: 7,
    iso_abbr: "CO",
    name: "Colorado"
  },
  {
    id: 8,
    iso_abbr: "CT",
    name: "Connecticut"
  },
  {
    id: 9,
    iso_abbr: "DE",
    name: "Delaware"
  },
  {
    id: 10,
    iso_abbr: "DC",
    name: "District of Columbia"
  },
  {
    id: 11,
    iso_abbr: "FM",
    name: "Federated States of Micronesia"
  },
  {
    id: 12,
    iso_abbr: "FL",
    name: "Florida"
  },
  {
    id: 13,
    iso_abbr: "GA",
    name: "Georgia"
  },
  {
    id: 14,
    iso_abbr: "GU",
    name: "Guam"
  },
  {
    id: 15,
    iso_abbr: "HI",
    name: "Hawaii"
  },
  {
    id: 16,
    iso_abbr: "ID",
    name: "Idaho"
  },
  {
    id: 17,
    iso_abbr: "IL",
    name: "Illinois"
  },
  {
    id: 18,
    iso_abbr: "IN",
    name: "Indiana"
  },
  {
    id: 19,
    iso_abbr: "IA",
    name: "Iowa"
  },
  {
    id: 20,
    iso_abbr: "KS",
    name: "Kansas"
  },
  {
    id: 21,
    iso_abbr: "KY",
    name: "Kentucky"
  },
  {
    id: 22,
    iso_abbr: "LA",
    name: "Louisiana"
  },
  {
    id: 23,
    iso_abbr: "ME",
    name: "Maine"
  },
  {
    id: 24,
    iso_abbr: "MH",
    name: "Marshall Islands"
  },
  {
    id: 25,
    iso_abbr: "MD",
    name: "Maryland"
  },
  {
    id: 26,
    iso_abbr: "MA",
    name: "Massachusetts"
  },
  {
    id: 27,
    iso_abbr: "MI",
    name: "Michigan"
  },
  {
    id: 28,
    iso_abbr: "MN",
    name: "Minnesota"
  },
  {
    id: 29,
    iso_abbr: "MS",
    name: "Mississippi"
  },
  {
    id: 30,
    iso_abbr: "MO",
    name: "Missouri"
  },
  {
    id: 31,
    iso_abbr: "MT",
    name: "Montana"
  },
  {
    id: 32,
    iso_abbr: "NE",
    name: "Nebraska"
  },
  {
    id: 33,
    iso_abbr: "NV",
    name: "Nevada"
  },
  {
    id: 34,
    iso_abbr: "NH",
    name: "New Hampshire"
  },
  {
    id: 35,
    iso_abbr: "NJ",
    name: "New Jersey"
  },
  {
    id: 36,
    iso_abbr: "NM",
    name: "New Mexico"
  },
  {
    id: 37,
    iso_abbr: "NY",
    name: "New York"
  },
  {
    id: 38,
    iso_abbr: "NC",
    name: "North Carolina"
  },
  {
    id: 39,
    iso_abbr: "ND",
    name: "North Dakota"
  },
  {
    id: 40,
    iso_abbr: "OH",
    name: "Ohio"
  },
  {
    id: 41,
    iso_abbr: "OK",
    name: "Oklahoma"
  },
  {
    id: 42,
    iso_abbr: "OR",
    name: "Oregon"
  },
  {
    id: 43,
    iso_abbr: "PW",
    name: "Palau"
  },
  {
    id: 44,
    iso_abbr: "PA",
    name: "Pennsylvania"
  },
  {
    id: 45,
    iso_abbr: "PR",
    name: "Puerto Rico"
  },
  {
    id: 46,
    iso_abbr: "RI",
    name: "Rhode Island"
  },
  {
    id: 47,
    iso_abbr: "SC",
    name: "South Carolina"
  },
  {
    id: 48,
    iso_abbr: "SD",
    name: "South Dakota"
  },
  {
    id: 49,
    iso_abbr: "TN",
    name: "Tennessee"
  },
  {
    id: 50,
    iso_abbr: "TX",
    name: "Texas"
  },
  {
    id: 51,
    iso_abbr: "UT",
    name: "Utah"
  },
  {
    id: 52,
    iso_abbr: "VT",
    name: "Vermont"
  },
  {
    id: 53,
    iso_abbr: "VI",
    name: "Virgin Islands"
  },
  {
    id: 54,
    iso_abbr: "VA",
    name: "Virginia"
  },
  {
    id: 55,
    iso_abbr: "WA",
    name: "Washington"
  },
  {
    id: 56,
    iso_abbr: "WV",
    name: "West Virginia"
  },
  {
    id: 57,
    iso_abbr: "WI",
    name: "Wisconsin"
  },
  {
    id: 58,
    iso_abbr: "WY",
    name: "Wyoming"
  }
];