import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import PatientInfo from '../components/PatientInfo';
import AddressInfo from '../components/AddressInfo';
import ProductInfo from '../components/ProductInfo';
import SurveyAnswers from '../components/SurveyAnswers';
import NotesList from '../components/NotesList';
import CreateNoteForm from '../components/CreateNoteForm';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import CertificateList from '../components/CertificateList';
import UploadOrderFile from '../components/UploadOrderFile';
import Alert from 'react-bootstrap/Alert';
import useAuth from '../components/useAuth';

async function orderDetailsRequest(token, orderId) {
 return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
   method: 'GET',
   headers: {
     'Content-Type': 'application/json',
     'Authorization': `Token ${token}`
   }
 })
   .then(data => data.json())
}

async function orderNotesRequest(token, orderId) {
 return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}/notes`, {
   method: 'GET',
   headers: {
     'Content-Type': 'application/json',
     'Authorization': `Token ${token}`
   }
 })
   .then(data => data.json())
}

async function orderCreateNoteRequest(token, orderId, payload) {
 return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}/notes`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
     'Authorization': `Token ${token}`
   },
   body: JSON.stringify(payload)
 })
   .then(data => data.json())
}

async function orderDeleteNoteRequest(token, orderId, noteId) {
 return fetch(`${process.env.REACT_APP_API_URL}/orders/${orderId}/notes/${noteId}`, {
   method: 'DELETE',
   headers: {
     'Content-Type': 'application/json',
     'Authorization': `Token ${token}`
   }
 })
}

export default function Orders() {
  const [order, setOrder] = useState(null);
  const [notes, setNotes] = useState(null);
  const [error, setError] = useState(null);
  let auth = useAuth();
  const [token, setToken] = useState(auth.token);
  

  let params = useParams();

  useEffect(() => {
    document.title = `Orders ${params.order_number} | Divan Medical`;
  });

  useEffect(() => {
    if(!order){
      getOrder()
    }
  }, [order]);

  useEffect(() => {
    if(!notes){
      getNotes()
    }
  }, [notes]);

  const getOrder = async () =>{
    let order = await orderDetailsRequest(token, params.order_number)
    setOrder(order)
  }

  const orderUpdateRequest = (updateData) => {
    setError(null)
    let callOptions = {headers: {'Authorization': `Token ${token}`}}
    axios.put(`${process.env.REACT_APP_API_URL}/orders/${order.order_number}`, {"order": updateData}, callOptions)
      .then(response => setOrder(response.data) )
      .catch(error => setError(error.message) )
  }

  const getNotes = async () =>{
    let notesArr = await orderNotesRequest(token, params.order_number)
    setNotes(notesArr)
  }

  const createNote = async (noteContent) =>{
    let data = { note: noteContent }
    let newNote = await orderCreateNoteRequest(token, params.order_number, data)
    setNotes(notes.concat(newNote))
  }

  const deleteNote = (noteId) => {
    orderDeleteNoteRequest(token, params.order_number, noteId)
    setNotes(notes.filter(note => note.id !== noteId))
  }

  const requestMedicalRecords = () =>{
    let callOptions = {headers: {'Authorization': `Token ${token}`}}
    setError(null)
    axios.get(`${process.env.REACT_APP_API_URL}/orders/${order.order_number}/request_medical_records`, callOptions)
          .then(response => alert("Medical Records Requested."))
          .catch(error => { if(error.response.status === 401){ sessionStorage.clear() }; setError('Something went wrong') } );
  }

  if(!order){
    return <div>...loading order</div>
  }
  
  return(
    <div className="row">
      <div className="col-12">
        <Link to={"/"} onClick={()=>{if(order?.aasm_state == "in_review") {orderUpdateRequest({aasm_state: "pending"})}}}>Back to Orders</Link>
      </div>
      <div className="col-md-6">
        <h2>Order #{params.order_number}</h2>
        {order && <><Badge bg="info" pill className="my-2">
          {order?.aasm_state.replace('_', ' ')}
        </Badge>
        
        {error && <Alert variant="danger">{error}</Alert>}
        {(order?.aasm_state === "in_review" || order?.aasm_state === "second_approval") && <div>
                <button onClick={()=>{orderUpdateRequest({continue: "true"})}} className="btn btn-primary mb-2 me-2">Approve Order</button>
                <button onClick={()=>{orderUpdateRequest({declined: "true"})}} className="btn btn-danger mb-2 me-2">Decline Order</button>
              </div>}
        {(order?.aasm_state === "appointment") && <div>
                <button onClick={()=>{orderUpdateRequest({aasm_state: "in_review", doctor_reviewing: auth.user.id})}} className="btn btn-outline-primary mb-2 me-2">Change to Pending</button>
              </div>}
        {(order?.aasm_state === "second_approval") && <div>
                <button onClick={()=>{orderUpdateRequest({aasm_state: "in_review"})}} className="btn btn-outline-primary mb-2 me-2">Change to Pending</button>
              </div>}
        <div><button onClick={()=>{requestMedicalRecords()}} className="btn btn-outline-primary mb-2">Request Medical Records</button></div>
        <div><Link to="video" className="btn btn-outline-primary mb-2">Start Video Call</Link></div>
        <p className="text-muted">Platform: {order?.platform}</p>
        {order?.aasm_state === "second_approval" && <p className="text-muted">1st Approval By: Dr. {order.doctor.last_name}</p>}
        <PatientInfo patient={order?.patient} />
        <AddressInfo address={order?.address} />
        <ProductInfo product={order?.product} />
        {notes && <NotesList notesArr={notes} deleteNote={deleteNote} />}
        <CreateNoteForm createNote={createNote} /></>}
        <CertificateList certificatesObj={order?.certificates} title="Certificates"/>
        <CertificateList certificatesObj={order?.path_documents} title="Documents"/>
        <UploadOrderFile token={token} orderId={params.order_number} setOrder={setOrder} setError={setError}/>
      </div>
      <div className="col-md-6">
        <SurveyAnswers surveyAnswers={order?.survey_response.all_answers} />
      </div>
    </div>
    
  );
}