import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

export default function CertificateList({certificatesObj, title}) {
  const certItems = Object.keys(certificatesObj).map((key)=>
    <ListGroup.Item key={key}><a href={process.env.REACT_APP_API_IMG_URL + certificatesObj[key]} rel="noreferrer" target="_blank">{title} - {key}</a></ListGroup.Item>
  );

  return(
    <div className="mt-3">
      <h2>{title}</h2>
      {certItems.length === 0 && <div>No {title} available</div>}
      <ListGroup>
        {certItems}
      </ListGroup>
    </div>   
  );
}