const SessionInfo = require('../ot/session/SessionInfo');

const isPassphraseSet = passphraseStore => Boolean(passphraseStore.get());

module.exports = (deps = {}) => {
  /* eslint-disable-next-line global-require */
  const hasE2eeCapability = deps.hasE2eeCapability || require('./hasE2eeCapability');

  /* eslint-disable-next-line global-require */
  const passphraseStore = deps.passphraseStore || require('../sframe/passphrase-store');

  if (hasE2eeCapability()) {
    const sessionInfo = deps.sessionInfo || new SessionInfo();
    const isE2eeSession = Boolean(sessionInfo.e2ee);

    return isE2eeSession && isPassphraseSet(passphraseStore);
  }

  return false;
};
