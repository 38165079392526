import React from 'react';

export default function PatientInfo({patient}) {
  return(
    <div className="row">
      <hr/>
      <div className="col">
        <h2>{patient.first_name} {patient.last_name}, {patient.age}</h2>
        <p>{patient.dob}</p>
        {patient.home_number && <p><a href={`tel:${patient.home_number.replace(/[^\d]/g, '')}`} >Home Number: {patient.home_number}</a></p>}
        {patient.mobile_number && <p><a href={`tel:${patient.mobile_number.replace(/[^\d]/g, '')}`} >Mobile Number: {patient.mobile_number}</a></p>}
        <p><a href={`mailto:${patient.email}`} >Email: {patient.email}</a></p>
        <p>Veteran: {patient.veteran ? "Yes" : "No"}</p>
      </div>
      <div className="col img-dl">
        <a href={process.env.REACT_APP_API_IMG_URL + patient.drivers_license} target="_blank">
          <img src={process.env.REACT_APP_API_IMG_URL + patient.drivers_license} alt={`${patient.first_name} ${patient.last_name}`} />
        </a>
      </div>
    </div>
    
  );
}