import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PlatformDropdown from './PlatformDropdown';

export default function OrderPagination({currentPage, totalPages, setCurrentPage, setPerPage, setOrderList, 
                          setOrderState, setFilterPlatform, setError}) {

  const getSelected = (e) => {
    let optionsArr = Array.from(e.target.selectedOptions).map(option => option.value)
    setOrderState(optionsArr)
    setOrderList(null)
  }

  let active = currentPage;
  const createPaginationItem = (i) => {
    return <Pagination.Item key={i} active={i === active} onClick={()=> {setCurrentPage(i); setOrderList(null)}}>
        {i}
      </Pagination.Item>
  };

  const paginationItems = [];
  // Add the first item (page 1)
  // paginationItems.push(createPaginationItem(1));
  let paginationTotal = totalPages >= 10 ? totalPages : 10
  let lessThanTen = totalPages <=10 ? totalPages : 10
  for (let number = 1; number <= lessThanTen; number++) {
    paginationItems.push(createPaginationItem(number));
  }
  if (paginationTotal > 10){
    paginationItems.push(<Pagination.Ellipsis key={"unique"}/>);
    paginationItems.push(createPaginationItem(totalPages));  
  }

  const paginationBasic = (
    <Pagination >
      {currentPage > 1 &&  <Pagination.Prev onClick={ ()=> (setCurrentPage(currentPage - 1), setOrderList(null)) } />}
      {paginationItems}
      {totalPages > currentPage && <Pagination.Next onClick={ ()=>(setCurrentPage(currentPage + 1), setOrderList(null)) } />}
    </Pagination>
  );


  return(
    <div className="row mb-3 justify-content-start">
      <div className="col-sm-6 mb-3">
        {paginationBasic}
      </div>
      
      <div className="col-sm-1 mb-3">
        <select onChange={(e) => (setPerPage(e.target.value),setCurrentPage(1) ,setOrderList(null) )} defaultValue={25} className="form-select">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
      <PlatformDropdown setFilterPlatform={setFilterPlatform} setError={setError} />
      <div className="col-sm-2">
        <label>Select order state</label>
        <select multiple onChange={ (e) => getSelected(e) } className="form-select">
          <option value="pending">Pending</option>
          <option value="second_approval">Second Approval</option>
          <option value="appointment">Appointment</option>
          <option value="approved">Approved</option>
          <option value="declined">Declined</option>
          <option value="in_review">In Review</option>
        </select>
      </div>
    </div>   
  );
}