import React from 'react';

export default function ProductInfo({product}) {
  return(
    <div>
      <hr/>
      <h2>Product</h2>
      <p>{product.name} ({product.state})</p>
    </div>
    
  );
}