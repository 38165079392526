import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss';
import Orders from './views/Orders'
import OrderShow from './views/OrderShow'
import Login from './views/Login'
import AuthProvider from './components/AuthContext'
import RequireAuth from './components/RequireAuth'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import OrderVideoCall from './views/OrderVideoCall'
import Navigation from './components/Navigation'
import Profile from './views/Profile'

Bugsnag.start({
  apiKey: '02c9d974c2e0e2428f0f867b85fe828f',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

function App() {
  useEffect(() => {
    document.title = `Divan Medical`;
  });
  
  return (
    <div>
      <ErrorBoundary>
        <AuthProvider >
          <BrowserRouter>
            <Navigation />
            <div className="container my-3">
              <Routes>
                <Route path="/" element={<RequireAuth><Orders /></RequireAuth>}/>
                <Route path="/login" element={<Login />} />
                <Route path="/orders" element={<RequireAuth><Orders /></RequireAuth>}/>
                <Route path="/orders/:order_number" element={<RequireAuth><OrderShow /></RequireAuth>}/>
                <Route path="/orders/:order_number/video" element={<RequireAuth><OrderVideoCall /></RequireAuth>}/>
                <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>}/>
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here! (404)</p>
                    </main>
                  }
                />
              </Routes>
            </div>
          </BrowserRouter>
        </AuthProvider>
        </ErrorBoundary>
    </div>
  );
}

export default App;
