/* eslint-disable max-len */
/* eslint-disable global-require, func-names */
/* eslint-disable no-use-before-define, no-prototype-builtins, no-underscore-dangle */
/* global MediaStreamTrack, MediaStreamTrackGenerator */

// @todo need to ensure logging for peer disconnected, and peer failures is intact

const get = require('lodash/get');
const assert = require('assert');
const assign = require('lodash/assign');
const cloneDeep = require('lodash/cloneDeep');
const find = require('lodash/find');
const isString = require('lodash/isString');
const pick = require('lodash/pick');
const once = require('lodash/once');
const startCase = require('lodash/startCase');
const uuid = require('uuid');
const capitalize = require('lodash/capitalize');
const WeakMap = require('es6-weak-map');
const { CancellationError, default: Cancellation } = require('cancel');

const env = require('../../helpers/env');
const setEncodersActiveStateDefault = require('./setEncodersActiveState');
const promisify = require('../../helpers/promisify');
const getStatsHelpers = require('../peer_connection/get_stats_helpers');
const eventNames = require('../../helpers/eventNames');
const eventing = require('../../helpers/eventing');
const promiseDelay = require('../../helpers/promiseDelay');
const Event = require('../../helpers/Event');
const AnalyticsHelperDefault = require('../../helpers/analytics');
const IntervalRunnerDefault = require('../interval_runner.js');
const createCleanupJobs = require('../../helpers/createCleanupJobs.js');
const whitelistPublisherProperties = require('./whitelistPublisherProperties');
const defaultWidgetView = require('../../helpers/widget_view')();
const audioLevelBehaviour = require('./audioLevelBehaviour');
const blockCallsUntilComplete = require('../../helpers/blockCallsUntilComplete');
const unblockAudio = require('../unblockAudio');
const { getInputMediaDevices } = require('../../helpers/device_helpers')();
const createCanvasVideoTrack = require('../../helpers/createCanvasVideoTrack');
const isSecureContextRequired = require('../../helpers/isSecureContextRequired');
const isGetRtcStatsReportSupported = require('../../helpers/isGetRtcStatsReportSupported');
const getDeviceIdFromStream = require('../../helpers/getDeviceIdFromStream');
const createStreamErrorMap = require('../../helpers/create-stream-error-map.js');
const { setVideoContentHint, getVideoContentHint } = require('../../helpers/videoContentHint');
const getMediaModeBySourceStreamId = require('../../helpers/get-media-mode-by-source-stream-id');

const KEEP_SENDING_MEDIA_AFTER_TRANSITIONED = 5 * 1000;
const KEEP_SENDING_MEDIA_TO_KEEP_ALIVE = 3 * 1000;
const KEEP_SENDING_RTCP_DELAY = 30 * 1000;

module.exports = function PublisherFactory({
  ...deps
} = {}) {
  ['processPubOptions'].forEach((key) => {
    assert(deps[key], `${key} dependency must be injected into Publisher`);
  });

  const AnalyticsHelper = deps.AnalyticsHelper || AnalyticsHelperDefault;
  const calculateCapableSimulcastStreams = deps.calculateCapableSimulcastStreams || require('./calculateCapableSimulcastStreams.js');
  const createChromeMixin = deps.createChromeMixin || require('./createChromeMixin.js')();
  const deviceHelpers = deps.deviceHelpers || require('../../helpers/device_helpers.js')();
  const EnvironmentLoader = deps.EnvironmentLoader || require('../environment_loader.js');
  const Errors = deps.Errors || require('../Errors.js');
  const Events = deps.Events || require('../events.js')();
  const ExceptionCodes = deps.ExceptionCodes || require('../exception_codes.js');
  const interpretPeerConnectionError = deps.interpretPeerConnectionError || require('../interpretPeerConnectionError.js')();
  const IntervalRunner = deps.IntervalRunner || IntervalRunnerDefault;
  const logging = deps.logging || require('../../helpers/log')('Publisher');
  const Microphone = deps.Microphone || require('./microphone.js')();
  const otError = deps.otError || require('../../helpers/otError.js')();
  const OTErrorClass = deps.OTErrorClass || require('../ot_error_class.js');
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const parseIceServers = deps.parseIceServers || require('../../RaptorSession/raptor/parseIceServers.js').parseIceServers;
  const PUBLISH_MAX_DELAY = deps.PUBLISH_MAX_DELAY || require('./max_delay.js');
  const PublisherPeerConnection = deps.PublisherPeerConnection || require('../peer_connection/publisher_peer_connection.js')();
  const PublishingState = deps.PublishingState || require('./state.js')();
  const StreamChannel = deps.StreamChannel || require('../stream_channel.js');
  const systemRequirements = deps.systemRequirements || require('../system_requirements.js');
  const VideoOrientation = deps.VideoOrientation || require('../../helpers/video_orientation.js')();
  const WidgetView = deps.WidgetView || defaultWidgetView;
  const windowMock = deps.global || global;
  const createSendMethod = deps.createSendMethod || require('../session/createSendMethod');
  const setEncodersActiveState = deps.setEncodersActiveState || setEncodersActiveStateDefault;
  const MediaProcessor = deps.MediaProcessor || require('../mediaProcessor/mediaProcessor');
  const isE2eeEnabled = deps.isE2eeEnabled || require('../../helpers/is-e2ee-enabled');

  const { processPubOptions } = deps;

  /**
   * The Publisher object  provides the mechanism through which control of the
   * published stream is accomplished. Calling the <code>OT.initPublisher()</code> method
   * creates a Publisher object. </p>
   *
   *  <p>The following code instantiates a session, and publishes an audio-video stream
   *  upon connection to the session: </p>
   *
   *  <pre>
   *  var apiKey = ''; // Replace with your API key. See https://tokbox.com/account
   *  var sessionID = ''; // Replace with your own session ID.
   *                      // See https://tokbox.com/developer/guides/create-session/.
   *  var token = ''; // Replace with a generated token that has been assigned the moderator role.
   *                  // See https://tokbox.com/developer/guides/create-token/.
   *
   *  var session = OT.initSession(apiKey, sessionID);
   *  session.connect(token, function(error) {
   *    if (error) {
   *      console.log(error.message);
   *    } else {
   *      // This example assumes that a DOM element with the ID 'publisherElement' exists
   *      var publisherProperties = {width: 400, height:300, name:"Bob's stream"};
   *      publisher = OT.initPublisher('publisherElement', publisherProperties);
   *      session.publish(publisher);
   *    }
   *  });
   *  </pre>
   *
   *      <p>This example creates a Publisher object and adds its video to a DOM element
   *      with the ID <code>publisherElement</code> by calling the <code>OT.initPublisher()</code>
   *      method. It then publishes a stream to the session by calling
   *      the <code>publish()</code> method of the Session object.</p>
   *
   * @property {Boolean} accessAllowed Whether the user has granted access to the camera
   * and microphone. The Publisher object dispatches an <code>accessAllowed</code> event when
   * the user grants access. The Publisher object dispatches an <code>accessDenied</code> event
   * when the user denies access.
   * @property {Element} element The HTML DOM element containing the Publisher. (<i>Note:</i>
   * when you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to
   * <a href="OT.html#initPublisher">OT.initPublisher</a>, the <code>element</code> property
   * is undefined.)
   * @property {String} id The DOM ID of the Publisher.
   * @property {Stream} stream The {@link Stream} object corresponding the stream of
   * the Publisher.
   * @property {Session} session The {@link Session} to which the Publisher belongs.
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher</a>
   * @see <a href="Session.html#publish">Session.publish()</a>
   *
   * @class Publisher
   * @augments EventDispatcher
   */
  const Publisher = function Publisher(options = {}) {
    let privateEvents = eventing({});

    const peerConnectionMetaMap = new WeakMap();

    /**
     * @typedef {Object} peerConnectionMeta
     * @property {String} remoteConnectionId The connection id of the remote side
     * @property {String} remoteSubscriberId The subscriber id of the remote side
     * @property {String} peerId The peerId of this peer connection
     * @property {String} peerConnectionId Our local identifier for this peer connection
     */

    /**
     * Retrieve meta information for this peer connection
     * @param {PublisherPeerConnection} peerConnection
     * @returns {peerConnectionMeta} meta data regarding this peer connection
     */
    const getPeerConnectionMeta = peerConnection => peerConnectionMetaMap.get(peerConnection);
    const setPeerConnectionMeta = (peerConnection, value) =>
      peerConnectionMetaMap.set(peerConnection, value);

    eventing(this);

    const streamCleanupJobs = createCleanupJobs();

    /** @type AnalyticsHelperDefault */
    let analytics = new AnalyticsHelper();

    // Check that the client meets the minimum requirements, if they don't the upgrade
    // flow will be triggered.
    if (!systemRequirements.check()) {
      systemRequirements.upgrade();
    }
    /** @type {WidgetView|null} */
    let widgetView;
    let lastRequestedStreamId;
    let webRTCStream;
    let publishStartTime;
    let microphone;
    let state;
    let rumorIceServers;
    let attemptStartTime;
    let audioDevices;
    let videoDevices;
    let selectedVideoInputDeviceId;
    let selectedAudioInputDeviceId;
    let didPublishComplete = false;
    let activeSourceStreamId;
    let _keepSendingRtcpToMantisTimeout;
    let _restartSendingRtpToMantisCalled;
    const hybridSessionTransitionStartTimes = {};
    const lastIceConnectionStates = {};
    let _streamDestroyTimeout;
    const STREAM_DESTROY_DELAY = 5000;

    /** @type IntervalRunnerDefault | undefined */
    let connectivityAttemptPinger;

    // previousSession mimics the publisher.session variable except it's never set to null
    // this allows analytics to refer to it in cases where we disconnect/destroy
    // and go to log analytics and publisher.session has been set to null
    let previousSession;

    const getLastSession = () =>
      this.session || previousSession || { isConnected() { return false; } };

    const streamChannels = [];

    const mediaProcessor = new MediaProcessor();

    this.once('publishComplete', (err) => {
      if (!err) {
        didPublishComplete = true;
        activeSourceStreamId = this.session?.sessionInfo.p2pEnabled ? 'P2P' : 'MANTIS';
      }
    });

    this.on('sourceStreamIdChanged', (newSourceStreamId) => {
      activeSourceStreamId = newSourceStreamId;
    });

    this.on('audioAcquisitionProblem', ({ method }) => {
      logAnalyticsEvent('publisher:audioAcquisitionProblem', 'Event', { didPublishComplete, method });
    });

    function getCommonAnalyticsFields() {
      return {
        connectionId: getLastSession().isConnected() ?
          getLastSession().connection.connectionId : null,
        streamId: lastRequestedStreamId,
        widgetType: 'Publisher',
      };
    }

    const onStreamAvailableError = (plainError) => {
      const names = Object.keys(Errors).map(shortName => Errors[shortName]);
      const error = otError(
        names.indexOf(plainError.name) > -1 ?
          plainError.name : Errors.MEDIA_ERR_ABORTED,
        plainError,
        ExceptionCodes.UNABLE_TO_PUBLISH
      );
      logging.error(`onStreamAvailableError ${error.name}: ${error.message}`);

      state.set('Failed');

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      const logOptions = {
        failureReason: 'GetUserMedia',
        failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
        failureMessage: `OT.Publisher failed to access camera/mic: ${error.message}`,
      };

      logConnectivityEvent('Failure', {}, logOptions);

      OTErrorClass.handleJsException({
        error,
        errorMsg: logOptions.failureReason,
        code: logOptions.failureCode,
        target: this,
        analytics,
      });

      this.trigger('publishComplete', error);
    };

    const onScreenSharingError = (errorParam) => {
      const error = cloneDeep(errorParam);
      error.code = ExceptionCodes.UNABLE_TO_PUBLISH;

      logging.error(`OT.Publisher.onScreenSharingError ${error.message}`);
      state.set('Failed');

      error.message = `Screensharing: ${error.message}`;

      this.trigger('publishComplete', error);

      logConnectivityEvent('Failure', {}, {
        failureReason: 'ScreenSharing',
        failureMessage: error.message,
      });

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }
    };

    // The user has clicked the 'deny' button in the allow access dialog, or it's
    // set to always deny, or the access was denied due to HTTP restrictions;
    const onAccessDenied = (errorParam) => {
      const error = cloneDeep(errorParam);

      let isIframe;

      try {
        isIframe = window.self !== window.top;
      } catch (err) {
        // ignore errors, (some browsers throw a security error when accessing cross domain)
      }

      if (global.location.protocol !== 'https:') {
        if (isScreenSharing) {
          /*
           * in http:// the browser will deny permission without asking the
           * user. There is also no way to tell if it was denied by the
           * user, or prevented from the browser.
           */
          error.message += ' Note: https:// is required for screen sharing.';
        } else if (isSecureContextRequired() && OTHelpers.env.hostName !== 'localhost') {
          error.message += ` Note: ${OTHelpers.env.name} requires HTTPS for camera and microphone access.`;
        }
      }

      if (isIframe && !isScreenSharing) {
        error.message += ' Note: Check that the iframe has the allow attribute for camera and microphone';
      }

      logging.error(error.message);

      state.set('Failed');

      // Note: The substring 'Publisher Access Denied:' is part of our api contract for now.
      // https://tokbox.com/developer/guides/publish-stream/js/#troubleshooting
      error.message = `OT.Publisher Access Denied: Permission Denied: ${error.message}`;
      error.code = ExceptionCodes.UNABLE_TO_PUBLISH;

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      logConnectivityEvent('Cancel', { reason: 'AccessDenied' });
      this.trigger('publishComplete', error);
      this.dispatchEvent(new Event(eventNames.ACCESS_DENIED));
    };

    const userMediaError = (error) => {
      const isPermissionError = error.name === Errors.USER_MEDIA_ACCESS_DENIED ||
        (error.name === Errors.NOT_SUPPORTED &&
          error.originalMessage.match(/Only secure origins/)
        );

      if (isPermissionError) {
        onAccessDenied(error);
      } else if (processPubOptions.isScreenSharing) {
        onScreenSharingError(error);
      } else {
        onStreamAvailableError(error);
      }

      throw error;
    };

    const onAccessDialogOpened = () => {
      logAnalyticsEvent('accessDialog', 'Opened');

      this.dispatchEvent(new Event(eventNames.ACCESS_DIALOG_OPENED, true));
    };

    const onAccessDialogClosed = () => {
      logAnalyticsEvent('accessDialog', 'Closed');

      this.dispatchEvent(new Event(eventNames.ACCESS_DIALOG_CLOSED, false));
    };

    const guid = uuid();
    const peerConnectionsAsync = {};
    let loaded = false;
    let previousAnalyticsStats = {};
    let audioAcquisitionProblemDetected = false;

    let processedOptions = processPubOptions(
      options,
      'OT.Publisher',
      () => (state && state.isDestroyed())
    );
    processedOptions.on({
      accessDialogOpened: onAccessDialogOpened,
      accessDialogClosed: onAccessDialogClosed,
    });
    const {
      isScreenSharing,
      isCustomAudioTrack,
      isCustomVideoTrack,
      shouldAllowAudio,
      properties,
      getUserMedia,
    } = processedOptions;

    // start with undefined
    Object.defineProperty(
      this,
      'loudness',
      { writable: false, value: undefined, configurable: true }
    );

    function removeTrackListeners(trackListeners) {
      trackListeners.forEach(off => off());
    }

    const listenWithOff = (obj, event, listener) => {
      if (!obj.addEventListener) {
        // noop
        return () => { };
      }
      obj.addEventListener(event, listener);
      return () =>
        obj.removeEventListener(event, listener);
    };

    (function handleAudioEnded() {
      const trackListeners = [];

      privateEvents.on('streamDestroy', () => removeTrackListeners(trackListeners));

      privateEvents.on('streamChange', () => {
        removeTrackListeners(trackListeners);
        const newListeners = webRTCStream.getAudioTracks().map(track =>
          listenWithOff(track, 'ended', () => {
            // chrome audio acquisition issue
            audioAcquisitionProblemDetected = true;
            this.trigger('audioAcquisitionProblem', { method: 'trackEndedEvent' });
          })
        );

        trackListeners.splice(0, trackListeners.length, ...newListeners);
      });
    }).call(this);

    (function handleMuteTrack() {
      const trackListeners = [];
      privateEvents.on('streamDestroy', () => removeTrackListeners(trackListeners));

      privateEvents.on('streamChange', () => {
        removeTrackListeners(trackListeners);

        // Check if we should ignore mute/unmute events coming from the tracks.
        // Further info in shouldSkipMuteEventsFromTrack()
        if (!shouldSkipMuteEventsFromTrack()) {
          webRTCStream.getTracks().forEach((track) => {
            if (track.addEventListener) {
              trackListeners.push(listenWithOff(track, 'mute', () => { refreshAudioVideoUI(); }));
              trackListeners.push(listenWithOff(track, 'unmute', () => { refreshAudioVideoUI(); }));
            }
          });
        }
      });
    }());

    // / Private Methods

    const shouldSkipMuteEventsFromTrack = () => {
      // Screensharing in Chrome sometimes triggers 'mute' and 'unmute'
      // repeatedly for no reason OPENTOK-37818
      // https://bugs.chromium.org/p/chromium/issues/detail?id=931033
      if (isScreenSharing) {
        return true;
      }
      // On Chrome when the custom video is static, we are receiving a mute event.
      // If videoContentHint suggests video could be static, i.e. text or detail
      // we will ignore the mute events coming from the video track.
      // Further info: OPENTOK-44289
      const staticContentHints = ['text', 'detail'];
      const isStaticContent = staticContentHints.includes(properties.videoContentHint);

      return isCustomVideoTrack && isStaticContent;
    };

    const logAnalyticsEvent = options.logAnalyticsEvent || (
      (action, variation, payload, logOptions, throttle) => {
        let stats = assign(
          { action, variation, payload },
          getCommonAnalyticsFields(),
          logOptions
        );

        if (variation === 'Failure') {
          stats = assign(previousAnalyticsStats, stats);
        }

        previousAnalyticsStats = pick(stats, 'sessionId', 'connectionId', 'partnerId');

        analytics.logEvent(stats, throttle);
      }
    );

    const logConnectivityEvent = (variation, payload = {}, logOptions = {}) => {
      if (logOptions.failureReason === 'Non-fatal') {
        // we don't want to log because it was a non-fatal failure
        return;
      }

      if (variation === 'Attempt') {
        attemptStartTime = new Date().getTime();

        if (connectivityAttemptPinger) {
          connectivityAttemptPinger.stop();
          logging.error('_connectivityAttemptPinger should have been cleaned up');
        }

        connectivityAttemptPinger = new IntervalRunner(
          () => {
            logAnalyticsEvent('Publish', 'Attempting', payload, {
              ...getCommonAnalyticsFields(),
              ...logOptions,
            });
          },
          1 / 5,
          6
        );
      }

      if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
        if (connectivityAttemptPinger) {
          connectivityAttemptPinger.stop();
          connectivityAttemptPinger = undefined;
        } else {
          logging.warn(`Received connectivity event: "${variation}" without "Attempt"`);
        }

        logAnalyticsEvent(
          'Publish',
          variation,
          {
            videoInputDevices: videoDevices,
            audioInputDevices: audioDevices,
            videoInputDeviceCount: videoDevices ? videoDevices.length : undefined,
            audioInputDeviceCount: audioDevices ? audioDevices.length : undefined,
            selectedVideoInputDeviceId,
            selectedAudioInputDeviceId,
            ...payload,
          },
          { attemptDuration: new Date().getTime() - attemptStartTime, ...logOptions }
        );
      } else {
        logAnalyticsEvent('Publish', variation, payload, logOptions);
      }
    };

    const logRepublish = (variation, payload) => {
      logAnalyticsEvent('ICERestart', variation, payload);
    };

    const logHybridSessionTransition = (action, variation, payload) => {
      if (variation === 'Attempt') {
        hybridSessionTransitionStartTimes[action] = new Date().getTime();
        logAnalyticsEvent(action, variation, payload);
      } else if (variation === 'Failure' || variation === 'Success') {
        logAnalyticsEvent(action, variation, payload,
          { attemptDuration: new Date().getTime() - hybridSessionTransitionStartTimes[action] });
      }
    };

    const logRoutedToRelayedTransition = (variation, payload = {}) => {
      logHybridSessionTransition('RoutedToRelayedTransition', variation, payload);
    };

    const logRelayedToRoutedTransition = (variation, payload = {}) => {
      logHybridSessionTransition('RelayedToRoutedTransition', variation, payload);
    };

    // logs an analytics event for getStats on the first call
    const notifyGetStatsCalled = once(() => {
      logAnalyticsEvent('GetStats', 'Called');
    });

    const notifyGetRtcStatsCalled = once(() => {
      logAnalyticsEvent('GetRtcStatsReport', 'Called');
    });

    const recordQOS = ({
      parsedStats,
      simulcastEnabled,
      remoteConnectionId,
      peerId,
      sourceStreamId,
    }) => {
      const QoSBlob = {
        peerId,
        widgetType: 'Publisher',
        connectionId: this.session && this.session.isConnected() ?
          this.session.connection.connectionId : null,
        streamId: lastRequestedStreamId,
        width: widgetView.width,
        height: widgetView.height,
        audioTrack: webRTCStream && webRTCStream.getAudioTracks().length > 0,
        hasAudio: hasAudio(),
        publishAudio: properties.publishAudio,
        videoTrack: webRTCStream && webRTCStream.getVideoTracks().length > 0,
        hasVideo: hasVideo(),
        publishVideo: properties.publishVideo,
        audioSource: isCustomAudioTrack ? 'Custom' : undefined,
        duration: publishStartTime ?
          Math.round((new Date().getTime() - publishStartTime.getTime()) / 1000) : 0,
        remoteConnectionId,
        scalableVideo: simulcastEnabled,
        sourceStreamId: getMediaModeBySourceStreamId(sourceStreamId),
      };

      let videoSource =
        (isScreenSharing && isCustomVideoTrack && 'Screen') ||
        (isScreenSharing && options.videoSource) ||
        (isCustomVideoTrack && 'Custom') ||
        (properties.constraints.video && 'Camera') ||
        null;

      // Normalize videoSource so that "application" becomes "Application"
      if (isString(videoSource)) {
        videoSource = startCase(videoSource);
      }
      QoSBlob.videoSource = videoSource;

      const videoDimensions = {
        videoWidth: this.videoWidth(),
        videoHeight: this.videoHeight(),
      };

      const parsedAndQosStats = assign({}, QoSBlob, parsedStats, videoDimensions);

      analytics.logQOS(parsedAndQosStats);
      this.trigger('qos', parsedAndQosStats);
    };

    // Returns the video dimensions. Which could either be the ones that
    // the developer specific in the videoDimensions property, or just
    // whatever the video element reports.
    //
    // If all else fails then we'll just default to 640x480
    //
    const getVideoDimensions = () => {
      let streamWidth;
      let streamHeight;
      const video = widgetView && widgetView.video();

      // We set the streamWidth and streamHeight to be the minimum of the requested
      // resolution and the actual resolution.
      if (properties.videoDimensions) {
        streamWidth = Math.min(properties.videoDimensions.width,
          (video && video.videoWidth()) || 640);
        streamHeight = Math.min(properties.videoDimensions.height,
          (video && video.videoHeight()) || 480);
      } else {
        streamWidth = (video && video.videoWidth()) || 640;
        streamHeight = (video && video.videoHeight()) || 480;
      }

      return {
        width: streamWidth,
        height: streamHeight,
      };
    };

    // / Private Events

    const stateChangeFailed = (changeFailed) => {
      logging.error('OT.Publisher State Change Failed: ', changeFailed.message);
      logging.debug(changeFailed);
    };

    const onLoaded = () => {
      if (state.isDestroyed()) {
        // The publisher was destroyed before loading finished
        if (widgetView) {
          widgetView.destroyVideo();
        }
        return;
      }

      logging.debug(
        'OT.Publisher.onLoaded; resolution:',
        `${this.videoWidth()}x${this.videoHeight()}`
      );

      state.set('MediaBound');

      // Try unblock audio on all subscribers
      unblockAudio().catch(logging.error);

      // If we have a session and we haven't created the stream yet then
      // wait until that is complete before hiding the loading spinner
      widgetView.loading(this.session ? !this.stream : false);

      loaded = true;
    };

    const onLoadFailure = (plainError) => {
      // eslint-disable-next-line no-param-reassign
      const err = otError(Errors.CONNECT_FAILED, plainError, ExceptionCodes.P2P_CONNECTION_FAILED);

      err.message = `OT.Publisher PeerConnection Error: ${err.message}`;

      logConnectivityEvent('Failure', {}, {
        failureReason: 'PeerConnectionError',
        failureCode: err.code,
        failureMessage: err.message,
      });

      state.set('Failed');

      this.trigger('publishComplete', err);

      OTErrorClass.handleJsException({
        error: err,
        target: this,
        analytics,
      });
    };

    // Clean up our LocalMediaStream
    const cleanupLocalStream = () => {
      if (webRTCStream) {
        privateEvents.emit('streamDestroy');
        // Stop revokes our access cam and mic access for this instance
        // of localMediaStream.
        if (windowMock.MediaStreamTrack && windowMock.MediaStreamTrack.prototype.stop) {
          // Newer spec
          webRTCStream.getTracks().forEach(track => track.stop());
        } else {
          // Older spec
          webRTCStream.stop();
        }
      }
    };

    const iOSRotatedVideoFeedBugHandler = () => {
      // In iOS 15+, when the page is put on background and then restored, the publisher video feed
      // is getting rotated. A workaround is to rebind the srcObject.
      const isBuggediOS = env.isiOS && env.iOSVersion >= 15 && env.iOSVersion < 15.2;
      const videoTrack = webRTCStream.getVideoTracks()[0];
      if (!isBuggediOS || !videoTrack) {
        return;
      }

      const visibilityHandler = () => {
        if (!document.hidden && widgetView) {
          widgetView.rebindSrcObject();
        }
      };
      document.addEventListener('visibilitychange', visibilityHandler);
    };

    const iOSBuggedLocalAudioTrackHandler = () => {
      // see https://bugs.webkit.org/show_bug.cgi?id=208209 & https://bugs.webkit.org/show_bug.cgi?id=208516
      // in iOS 13.3 and later, the audiotrack sometimes fails to unmute after receivng a phone call
      // this work around relies on the visibility of the page to see if the call is over
      const isBuggediOS = env.isiOS && env.iOSVersion >= 13.3;
      const audioTrack = webRTCStream.getAudioTracks()[0];
      if (isBuggediOS && audioTrack) {
        audioTrack.onmute = () => (handleBuggedMutedLocalAudioTrack(audioTrack));
        // When iOS uses compact UI for phone calls, the visibility of the page is not relevant.
        // As a workaround for this specific case, when the track is back to unmuted
        // we reset the audio source. See OPENTOK-42233.
        audioTrack.onunmute = () => (handleBuggedUnMutedLocalAudioTrack(audioTrack));
      }
    };

    const bindVideo = async () => {
      const videoContainerOptions = {
        muted: true,
      };

      if (!widgetView) {
        throw new Error('Cannot bind video after widget view has been destroyed');
      }

      return widgetView.bindVideo(webRTCStream, videoContainerOptions);
    };

    const onStreamAvailable = async (webOTStream) => {
      logging.debug('OT.Publisher.onStreamAvailable');

      state.set('BindingMedia');

      if (properties.videoContentHint !== undefined) {
        setVideoContentHint(webOTStream, properties.videoContentHint);
      }

      cleanupLocalStream();
      webRTCStream = webOTStream;

      if (properties.videoFilter) {
        try {
          await this.applyVideoFilter(properties.videoFilter);
        } catch (err) {
          logging.error(`Error applying video filter: ${err}`);
        }
      }

      privateEvents.emit('streamChange');

      iOSBuggedLocalAudioTrackHandler();
      iOSRotatedVideoFeedBugHandler();

      const findSelectedDeviceId = (tracks, devices) => {
        // Store the device labels to log later
        let selectedDeviceId;
        tracks.forEach((track) => {
          if (track.deviceId) {
            selectedDeviceId = track.deviceId.toString();
          } else if (track.label && devices) {
            const selectedDevice = find(devices, el => el.label === track.label);
            if (selectedDevice) {
              selectedDeviceId = selectedDevice.deviceId;
            }
          }
        });
        return selectedDeviceId;
      };

      selectedVideoInputDeviceId = findSelectedDeviceId(
        webRTCStream.getVideoTracks(), videoDevices
      );
      selectedAudioInputDeviceId = findSelectedDeviceId(
        webRTCStream.getAudioTracks(), audioDevices
      );

      microphone = new Microphone(webRTCStream, !properties.publishAudio);
      updateVideo();
      updateAudio();

      this.accessAllowed = true;

      this.dispatchEvent(new Event(eventNames.ACCESS_ALLOWED, false));
    };

    const onPublishingTimeout = (session) => {
      logging.error('OT.Publisher.onPublishingTimeout');

      let errorName;
      let errorMessage;

      if (audioAcquisitionProblemDetected) {
        errorName = Errors.CHROME_MICROPHONE_ACQUISITION_ERROR;
        errorMessage = 'Unable to publish because your browser failed to get access to your ' +
          'microphone. You may need to fully quit and restart your browser to get it to work. ' +
          'See https://bugs.chromium.org/p/webrtc/issues/detail?id=4799 for more details.';
      } else {
        errorName = Errors.TIMEOUT;
        errorMessage = 'Could not publish in a reasonable amount of time';
      }

      const logOptions = {
        failureReason: 'ICEWorkflow',
        failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
        failureMessage: 'OT.Publisher failed to publish in a reasonable amount of time (timeout)',
      };

      logConnectivityEvent('Failure', {}, logOptions);

      OTErrorClass.handleJsException({
        errorMsg: logOptions.failureReason,
        code: logOptions.failureCode,
        target: this,
        analytics,
      });

      if (session.isConnected() && this.streamId) {
        session._.streamDestroy(this.streamId);
      }

      // Disconnect immediately, rather than wait for the WebSocket to
      // reply to our destroyStream message.
      this.disconnect();

      this.session = null;

      // We're back to being a stand-alone publisher again.
      if (!state.isDestroyed()) { state.set('MediaBound'); }

      this.trigger(
        'publishComplete',
        otError(
          errorName,
          new Error(errorMessage),
          ExceptionCodes.UNABLE_TO_PUBLISH
        )
      );
    };

    const onVideoError = (plainErr) => {
      // eslint-disable-next-line no-param-reassign
      const err = otError(Errors.MEDIA_ERR_DECODE, plainErr, ExceptionCodes.UNABLE_TO_PUBLISH);
      err.message = `OT.Publisher while playing stream: ${err.message}`;

      logging.error('OT.Publisher.onVideoError:', err);
      logAnalyticsEvent('stream', null, { reason: err.message });

      // Check if attempting to publish *before* overwriting the state
      const isAttemptingToPublish = state.isAttemptingToPublish();
      state.set('Failed');

      if (isAttemptingToPublish) {
        this.trigger('publishComplete', err);
      } else {
        // FIXME: This emits a string instead of an error here for backwards compatibility despite
        // being undocumented. When possible we should remove access to this and other undocumented
        // events, and restore emitting actual errors here.
        this.trigger('error', err.message);
      }

      OTErrorClass.handleJsException({
        error: err,
        target: this,
        analytics,
      });
    };

    // Makes it easier to unit test stuff
    this._setWebRTCStream = (stream) => {
      webRTCStream = stream;
    };

    // Makes it easier to unit test stuff
    this._setCurrentVideoFilter = (filter) => {
      currentVideoFilter = filter;
    };

    // Makes it easier to unit test stuff
    this._getCurrentVideoFilter = () => currentVideoFilter;

    this._removePeerConnection = (peerConnection) => {
      const { peerConnectionId } = getPeerConnectionMeta(peerConnection);

      delete peerConnectionsAsync[peerConnectionId];

      peerConnection.destroy();
    };


    this._removeSubscriber = (subscriberId) => {
      const { isAdaptiveEnabled } = this.session.sessionInfo;
      if (isAdaptiveEnabled && activeSourceStreamId === 'P2P') {
        this._.startRelayedToRoutedTransition();
      }
      getPeerConnectionsBySubscriber(subscriberId).then((peerConnections) => {
        peerConnections.forEach(pc => this._removePeerConnection(pc));
      });
    };

    const onPeerDisconnected = (peerConnection) => {
      const { remoteSubscriberId, peerConnectionId } = getPeerConnectionMeta(peerConnection);

      logging.debug('Subscriber has been disconnected from the Publisher\'s PeerConnection');
      logAnalyticsEvent('disconnect', 'PeerConnection', { subscriberConnection: peerConnectionId });

      this._removeSubscriber(remoteSubscriberId);
    };

    // @todo find out if we get onPeerDisconnected when a failure occurs.
    const onPeerConnectionFailure = async (peerConnection, { reason, prefix }) => {
      const sessionInfo = this.session && this.session.sessionInfo;

      if (prefix === 'ICEWorkflow' && sessionInfo && sessionInfo.reconnection && loaded) {
        // @todo not sure this is the right thing to do
        logging.debug('Ignoring peer connection failure due to possibility of reconnection.');
        return;
      }

      const { remoteConnectionId = '(not found)', peerConnectionId } = getPeerConnectionMeta(peerConnection) || {};

      const error = interpretPeerConnectionError(undefined, reason, prefix, remoteConnectionId, 'Publisher');

      const payload = {
        hasRelayCandidates: peerConnection && peerConnection.hasRelayCandidates(),
      };

      const logOptions = {
        failureReason: prefix || 'PeerConnectionError',
        failureCode: error.code,
        failureMessage: error.message,
      };

      if (state.isPublishing()) {
        // We're already publishing so this is a Non-fatal failure, must be p2p and one of our
        // peerconnections failed
        logOptions.reason = 'Non-fatal';
      } else {
        this.trigger('publishComplete', error);
      }

      logConnectivityEvent('Failure', payload, logOptions);

      OTErrorClass.handleJsException({
        errorMsg: `OT.Publisher PeerConnection Error: ${reason}`,
        code: error.code,
        target: this,
        analytics,
      });

      const pc = await peerConnectionsAsync[peerConnectionId];
      pc.destroy();
      delete peerConnectionsAsync[peerConnectionId];
    };

    const isRoutedToRelayedTransitionComplete = (peerConnection) => {
      const { isAdaptiveEnabled } = this.session.sessionInfo;
      return isAdaptiveEnabled &&
        peerConnection.getSourceStreamId() === 'P2P';
    };

    const onIceRestartSuccess = (peerConnection) => {
      const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
      logRepublish('Success', { remoteConnectionId });
    };

    const onIceRestartFailure = (peerConnection) => {
      const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
      logRepublish('Failure', {
        reason: 'ICEWorkflow',
        message: 'OT.Publisher PeerConnection Error: ' +
          'The stream was unable to connect due to a network error.' +
          ' Make sure your connection isn\'t blocked by a firewall.',
        remoteConnectionId,
      });
    };

    const onIceConnectionStateChange = async (newState, peerConnection) => {
      const { isAdaptiveEnabled } = this.session.sessionInfo;
      const sourceStreamId = peerConnection.getSourceStreamId();

      lastIceConnectionStates[sourceStreamId] = newState;

      if (newState === 'disconnected') {
        setTimeout(() => {
          const isSocketReconnecting = this.session._.isSocketReconnecting;
          const socket = this.session._.getSocket();
          const isSocketConnected = socket.is('connected') && !isSocketReconnecting();

          if (lastIceConnectionStates[sourceStreamId] === 'disconnected' && isSocketConnected) {
            const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
            logRepublish('Attempt', { remoteConnectionId });
            peerConnection.iceRestart();
          }
        }, 2000);
      }

      if (newState === 'connected') {
        clearTimeout(_streamDestroyTimeout);
        if (isAdaptiveEnabled) {
          // In an Adaptive session, when a P2P peer connection state is connected
          // we stop sending media to MANTIS since now the media is flowing through the P2P leg.
          const isMantisConnected =
            (await getMantisPeerConnection()).iceConnectionStateIsConnected();
          const isP2PConnected = (await getP2pPeerConnection()).iceConnectionStateIsConnected();
          if (isMantisConnected && isP2PConnected) {
            _stopSendingRtpToMantis();
          }
        }
      }

      if (newState === 'failed') {
        const isSocketReconnecting = this.session._.isSocketReconnecting;
        const socket = this.session._.getSocket();
        const isSocketConnected = socket.is('connected') && !isSocketReconnecting();
        if (!isSocketConnected) {
          // We do not destroy the publisher if socket is not connected, since we will try
          // to reconnect once socket reconnects
          return;
        }
        // If PC has failed and the socket is connected we will either transition to Mantis
        // if adaptive and P2P leg or destroy the publisher in all other cases
        // Instead of destroying the publisher straight away, we will destroy it after 5 secs
        // in order to avoid a race condition where we just got the socket connected at the
        // same moment PC transition to failed
        if (isAdaptiveEnabled && sourceStreamId === activeSourceStreamId && sourceStreamId === 'P2P') {
          this._.startRelayedToRoutedTransition();
        } else {
          _streamDestroyTimeout = setTimeout(() => {
            this.session._.streamDestroy(this.streamId, sourceStreamId);
          }, STREAM_DESTROY_DELAY);
        }
      }
    };

    const onPeerConnected = (peerConnection) => {
      peerConnection.startEncryption(this.session.connection.id);
      if (isRoutedToRelayedTransitionComplete(peerConnection)) {
        logRoutedToRelayedTransition('Success');
      }
    };

    // / Private Helpers

    // Assigns +stream+ to this publisher. The publisher listens for a bunch of events on the stream
    // so it can respond to changes.

    const assignStream = (stream) => {
      // the Publisher only expects a stream in the PublishingToSession state
      if (state.current !== 'PublishingToSession') {
        throw new Error('assignStream called when publisher is not successfully publishing');
      }

      streamCleanupJobs.releaseAll();
      this.stream = stream;
      this.stream.on('destroyed', this.disconnect, this);
      streamCleanupJobs.add(() => {
        if (this.stream) {
          this.stream.off('destroyed', this.disconnect, this);
        }
      });

      state.set('Publishing');
      widgetView.loading(!loaded);
      publishStartTime = new Date();

      this.dispatchEvent(new Events.StreamEvent('streamCreated', stream, null, false));

      logConnectivityEvent('Success');

      this.trigger('publishComplete', null, this);
    };

    /**
     * Provides the peer connection associated to the given peerConnectionId.
     *
     * It there is no PC associated it creates a new one and stores it so that the next call returns
     * the same instance.
     *
     * @param {Object} configuration
     * @param {string} configuration.peerConnectionId
     * @returns {Promise<Error, PublisherPeerConnection>}
     */
    const createPeerConnection = ({
      peerConnectionId,
      send,
      log,
      logQoS,
      sourceStreamId,
    }) => {
      if (getPeerConnectionById(peerConnectionId)) {
        return Promise.reject(new Error('PeerConnection already exists'));
      }

      // Calculate the number of streams to use. 1 for normal, >1 for Simulcast
      const capableSimulcastStreams = calculateCapableSimulcastStreams({
        isChromiumEdge: OTHelpers.env.isChromiumEdge,
        browserName: OTHelpers.env.name,
        isScreenSharing,
        isCustomVideoTrack,
        sessionInfo: this.session.sessionInfo,
        constraints: properties.constraints,
        videoDimensions: getVideoDimensions(),
        capableSimulcastScreenshare: properties.capableSimulcastScreenshare,
      });

      peerConnectionsAsync[peerConnectionId] = Promise
        .all([
          this.session._.getIceConfig(),
          this.session._.getVideoCodecsCompatible(webRTCStream),
        ])
        .then(([iceConfig, videoCodecsCompatible]) => {
          let pcStream = webRTCStream;
          if (!videoCodecsCompatible) {
            pcStream = webRTCStream.clone();

            const [videoTrack] = pcStream.getVideoTracks();

            if (videoTrack) {
              videoTrack.stop();
              pcStream.removeTrack(videoTrack);
            }
          }

          const peerConnection = new PublisherPeerConnection({
            iceConfig,
            sendMessage: (type, content) => {
              if (type === 'offer') {
                this.trigger('connected');
              }
              send(type, content);
            },
            webRTCStream: pcStream,
            channels: properties.channels,
            capableSimulcastStreams,
            overrideSimulcastEnabled: options._enableSimulcast,
            logAnalyticsEvent: log,
            offerOverrides: {
              enableDtx: properties.enableDtx,
              enableStereo: properties.enableStereo,
              audioBitrate: properties.audioBitrate,
              priorityVideoCodec: properties._priorityVideoCodec ||
                this.session.sessionInfo.priorityVideoCodec,
              codecFlags: properties._codecFlags || this.session._.getCodecFlags(),
            },
            // FIXME - Remove answerOverrides once maxaveragebitrate is supported by Mantis
            answerOverrides: (this.session.sessionInfo.p2pEnabled ? undefined : {
              audioBitrate: properties.audioBitrate,
            }),
            sourceStreamId,
            isP2pEnabled: this.session.sessionInfo.p2pEnabled,
            sessionId: this.session.id,
          });

          peerConnection.on({
            disconnected: () => onPeerDisconnected(peerConnection),
            error: ({ reason, prefix }) =>
              onPeerConnectionFailure(peerConnection, { reason, prefix }),
            qos: logQoS,
            iceRestartSuccess: () => onIceRestartSuccess(peerConnection),
            iceRestartFailure: () => onIceRestartFailure(peerConnection),
            iceConnectionStateChange: newState =>
              onIceConnectionStateChange(newState, peerConnection),
            audioAcquisitionProblem: () => {
              // will be only triggered in Chrome
              audioAcquisitionProblemDetected = true;
              this.trigger('audioAcquisitionProblem', { method: 'getStats' });
            },
          });

          peerConnection.once('connected', () => onPeerConnected(peerConnection));

          return new Promise((resolve, reject) => {
            const rejectOnError = (err) => {
              reject(err);
            };
            peerConnection.once('error', rejectOnError);
            peerConnection.init(rumorIceServers, (err) => {
              if (err) { return reject(err); }
              peerConnection.off('error', rejectOnError);
              resolve(peerConnection);
              return undefined;
            });
          });
        });

      return getPeerConnectionById(peerConnectionId);
    };

    const getAllPeerConnections = () =>
      Promise.all(Object.keys(peerConnectionsAsync).map(getPeerConnectionById));

    const getPeerConnectionsBySubscriber = subscriberId =>
      getAllPeerConnections().then(peerConnections =>
        peerConnections.filter(peerConnection =>
          getPeerConnectionMeta(peerConnection).remoteSubscriberId === subscriberId
        )
      );

    const getPeerConnectionById = id => peerConnectionsAsync[id];

    const getPeerConnectionBySourceStreamId = (sourceStreamId) => {
      // Find the peerConnectionId which includes the sourceStreamId that we're looking for.
      const peerConnectionId = Object.keys(peerConnectionsAsync).find(
        key => key.endsWith(`~${sourceStreamId}`));

      return peerConnectionsAsync[peerConnectionId];
    };

    const getMantisPeerConnection = () => getPeerConnectionBySourceStreamId('MANTIS');
    const getP2pPeerConnection = () => getPeerConnectionBySourceStreamId('P2P');

    let chromeMixin = createChromeMixin(this, {
      name: properties.name,
      publishAudio: properties.publishAudio,
      publishVideo: properties.publishVideo,
      audioSource: properties.audioSource,
      showControls: properties.showControls,
      shouldAllowAudio,
      logAnalyticsEvent,
    });

    const reset = () => {
      this.off('publishComplete', refreshAudioVideoUI);
      if (chromeMixin) {
        chromeMixin.reset();
      }

      streamCleanupJobs.releaseAll();
      this.disconnect();

      microphone = null;

      cleanupLocalStream();
      webRTCStream = null;

      if (widgetView) {
        widgetView.destroy();
        widgetView = null;
      }

      if (this.session) {
        this._.unpublishFromSession(this.session, 'reset');
      }

      this.id = null;
      this.stream = null;
      loaded = false;

      this.session = null;

      if (!state.isDestroyed()) { state.set('NotPublishing'); }
    };

    const hasVideo = () => {
      if (!webRTCStream || webRTCStream.getVideoTracks().length === 0) {
        return false;
      }
      // On Chrome when screensharing/custom video is static, it is swapping between 'mute' and
      // 'unmute' states periodically for no reason OPENTOK-37818
      // https://bugs.chromium.org/p/chromium/issues/detail?id=931033
      // We will ignore track.muted when screensharing and custom when videoContentHint
      // suggests video could be static, i.e. text or detail
      const staticContentHints = ['text', 'detail'];
      const isStaticContent = staticContentHints.includes(properties.videoContentHint);
      const isStaticCustom = isCustomVideoTrack && isStaticContent;
      const shouldIgnoreTrackMuteState = OTHelpers.env.isChrome && (isScreenSharing || isStaticCustom);
      return webRTCStream.getVideoTracks().reduce(
        (isEnabled, track) => isEnabled && (!track.muted || !!shouldIgnoreTrackMuteState) &&
         track.enabled && track.readyState !== 'ended',
        properties.publishVideo
      );
    };

    const hasAudio = () => {
      if (!webRTCStream || webRTCStream.getAudioTracks().length === 0) {
        return false;
      }
      return webRTCStream.getAudioTracks().length > 0 && webRTCStream.getAudioTracks().reduce(
        (isEnabled, track) => isEnabled && !track.muted && track.enabled && track.readyState !== 'ended',
        properties.publishAudio
      );
    };

    const refreshAudioVideoUI = (activeReason) => {
      if (widgetView) {
        widgetView.audioOnly(!hasVideo());
        widgetView.showPoster(!hasVideo());
      }

      if (chromeMixin) {
        chromeMixin.setAudioOnly(!hasVideo() && hasAudio());
      }

      if (this.stream) {
        this.stream.setChannelActiveState('audio', hasAudio(), activeReason);
        this.stream.setChannelActiveState('video', hasVideo(), activeReason);
      } else {
        this.once('publishComplete', refreshAudioVideoUI);
      }
    };

    const _getStatsWrapper = (reportType, callback) => {
      let isRtcStatsReport = false;

      if (typeof reportType === 'function') {
        /* eslint-disable-next-line no-param-reassign */
        callback = reportType;
      } else {
        isRtcStatsReport = reportType === 'rtcStatsReport';
      }

      if (isRtcStatsReport) {
        notifyGetRtcStatsCalled();
      } else {
        notifyGetStatsCalled();
      }

      if (isRtcStatsReport && !isGetRtcStatsReportSupported) {
        const errorCode = ExceptionCodes.GET_RTC_STATS_REPORT_NOT_SUPPORTED;
        callback(otError(
          Errors.GET_RTC_STATS_REPORT_NOT_SUPPORTED,
          new Error(OTErrorClass.getTitleByCode(errorCode)),
          errorCode
        ));
        return;
      }

      getAllPeerConnections()
        .then((peerConnections) => {
          if (peerConnections.length === 0) {
            const errorCode = ExceptionCodes.PEER_CONNECTION_NOT_CONNECTED;
            throw otError(
              Errors.PEER_CONNECTION_NOT_CONNECTED,
              new Error(OTErrorClass.getTitleByCode(errorCode)),
              errorCode
            );
          }
          return peerConnections;
        })
        .then((peerConnections) => {
          const { isAdaptiveEnabled } = this.session.sessionInfo;

          if (!isAdaptiveEnabled) {
            return peerConnections;
          }

          // When the session is adaptive, we only return stats for the active peer connection.
          return peerConnections
            .filter(pc => pc.getSourceStreamId() === activeSourceStreamId);
        })
        .then(peerConnections =>
          Promise.all(peerConnections.map(
            peerConnection =>
              (isRtcStatsReport ?
                promisify(:: peerConnection.getRtcStatsReport) :
                promisify(:: peerConnection.getStats))()
                .then(stats => ({ pc: peerConnection, stats }))
          ))
        )
        .then((pcsAndStats) => {
          // @todo this publishStartTime is going to be so wrong in P2P
          const startTimestamp = publishStartTime ? publishStartTime.getTime() : Date.now();
          const results = pcsAndStats.map(({ pc, stats }) => {
            const { remoteConnectionId, remoteSubscriberId } = getPeerConnectionMeta(pc);
            return assign(
              remoteConnectionId.match(/^symphony\./) ? {} : {
                subscriberId: remoteSubscriberId,
                connectionId: remoteConnectionId,
              },
              isRtcStatsReport ?
                { rtcStatsReport: stats } :
                { stats: getStatsHelpers.normalizeStats(stats, false, startTimestamp) }
            );
          });
          callback(null, results);
        })
        .catch(callback);
    };

    const _getStats = callback => _getStatsWrapper(callback);

    const _getRtcStatsReport = callback => _getStatsWrapper('rtcStatsReport', callback);

    const _createStream = (sourceStreamId, completionHandler) => {
      this.session._.streamCreate(
        properties.name || '',
        this.streamId,
        properties.audioFallbackEnabled,
        streamChannels,
        properties.minVideoBitrate,
        sourceStreamId,
        completionHandler
      );
    };

    const _stopSendingRtpToMantis = async () => {
      _restartSendingRtpToMantisCalled = false;
      const peerConnection = await getMantisPeerConnection();
      if (peerConnection) {
        this.trigger('sourceStreamIdChanged', 'P2P');

        // We add this delay before stopping media to prevent MANTIS to consider this stream
        // as inactive after a reconnection and then destroy it.
        await promiseDelay(KEEP_SENDING_MEDIA_AFTER_TRANSITIONED);

        // In case _restartSendingRtpToMantis() was invoked while waiting for
        // KEEP_SENDING_MEDIA_AFTER_TRANSITIONED promise to finish, we cancel the media direction change.
        if (_restartSendingRtpToMantisCalled) {
          logging.debug('Cancelling stop sending RTP to MANTIS.');
          return;
        }
        await peerConnection.changeMediaDirectionToInactive();

        // In FF < v96, when the media direction is changed to inactive, it stops sending RTCP.
        // This causes that after ~60 seconds, MANTIS considers the stream is inactive
        // and destroys it.
        // As a workaround, we are going to send RTP and RTCP every 30 seconds to keep the
        // connection alive. See: OPENTOK-44341
        if (OTHelpers.env.isFirefox && OTHelpers.env.version < 96) {
          await _keepSendingRtcpToMantis();
        }
      }
    };

    const _restartSendingRtpToMantis = async () => {
      _restartSendingRtpToMantisCalled = true;
      const peerConnection = await getMantisPeerConnection();
      if (peerConnection) {
        await peerConnection.changeMediaDirectionToRecvOnly();
        if (_keepSendingRtcpToMantisTimeout) {
          clearTimeout(_keepSendingRtcpToMantisTimeout);
        }
        this.trigger('sourceStreamIdChanged', 'MANTIS');
      }
    };

    const _keepSendingRtcpToMantis = async () => {
      const peerConnection = await getMantisPeerConnection();
      if (peerConnection) {
        _keepSendingRtcpToMantisTimeout = setTimeout(async () => {
          if (activeSourceStreamId === 'P2P') {
            await peerConnection.changeMediaDirectionToRecvOnly();
            // Wait a bit before setting the media direction back to inactive to avoid
            // conflicts in the peer connection state.
            await promiseDelay(KEEP_SENDING_MEDIA_TO_KEEP_ALIVE);
            await peerConnection.changeMediaDirectionToInactive();
            await _keepSendingRtcpToMantis();
          }
        }, KEEP_SENDING_RTCP_DELAY);
      }
    };

    this.publish = (targetElement) => {
      logging.debug('OT.Publisher: publish');

      if (state.isAttemptingToPublish() || state.isPublishing()) {
        reset();
      }
      state.set('GetUserMedia');

      if (properties.style) {
        this.setStyle(properties.style, null, true);
      }

      properties.classNames = 'OT_root OT_publisher';

      // Defer actually creating the publisher DOM nodes until we know
      // the DOM is actually loaded.
      EnvironmentLoader.onLoad(() => {
        logging.debug('OT.Publisher: publish: environment loaded');
        // @note If ever replacing the widgetView with a new one elsewhere, you'll need to be
        // mindful that audioLevelBehaviour has a reference to this one, and it will need to be
        // updated accordingly.
        // widgetView = new WidgetView(targetElement, properties);
        widgetView = new WidgetView(targetElement, { ...properties, widgetType: 'publisher' });

        if (shouldAllowAudio) {
          audioLevelBehaviour({ publisher: this, widgetView });
        }

        widgetView.on('error', onVideoError);

        this.id = widgetView.domId();
        this.element = widgetView.domElement;

        if (this.element && chromeMixin) {
          // Only create the chrome if we have an element to insert it into
          // for insertDefautlUI:false we don't create the chrome
          chromeMixin.init(widgetView);
        }

        widgetView.on('videoDimensionsChanged', (oldValue, newValue) => {
          if (this.stream) {
            this.stream.setVideoDimensions(newValue.width, newValue.height);
          }
          this.dispatchEvent(
            new Events.VideoDimensionsChangedEvent(this, oldValue, newValue)
          );
        });

        widgetView.on('mediaStopped', (track) => {
          const event = new Events.MediaStoppedEvent(this, track);

          this.dispatchEvent(event);

          if (event.isDefaultPrevented()) {
            return;
          }

          if (track) {
            const kind = String(track.kind).toLowerCase();
            // If we are publishing this kind when the track stops then
            // make sure we start publishing again if we switch to a new track
            if (kind === 'audio') {
              updateAudio();
            } else if (kind === 'video') {
              updateVideo();
            } else {
              logging.warn(`Track with invalid kind has ended: ${track.kind}`);
            }
            return;
          }

          if (this.session) {
            this._.unpublishFromSession(this.session, 'mediaStopped');
          } else {
            this.destroy('mediaStopped');
          }
        });

        widgetView.on('videoElementCreated', (element) => {
          const event = new Events.VideoElementCreatedEvent(element);
          this.dispatchEvent(event);
        });

        getUserMedia()
          .catch(userMediaError)
          .then(
            async (stream) => {
              // this comes from deviceHelpers.shouldAskForDevices in a round-about way
              audioDevices = processedOptions.audioDevices;
              videoDevices = processedOptions.videoDevices;

              const hasVideoFilter = !!properties.videoFilter;

              if (hasVideoFilter) {
                // We need to get the device now, before the filter is applied
                // else the wrong device will be returned/nonsensical
                currentDeviceId = getDeviceIdFromStream(stream, videoDevices);
              }

              await onStreamAvailable(stream);
              if (!properties.publishVideo) {
                this._toggleVideo(properties.publishVideo);
              }

              if (!isScreenSharing && !isCustomVideoTrack
                // For filtered video, we stored the currentDeviceId already.
                // (see note above)
                && !hasVideoFilter) {
                currentDeviceId = getDeviceIdFromStream(stream, videoDevices);
              }

              return bindVideo()
                .catch((error) => {
                  if (error instanceof CancellationError) {
                    // If we get a CancellationError, it means something newer tried
                    // to bindVideo before the old one succeeded, perhaps they called
                    // switchTracks.. It should be rare, and they shouldn't be doing
                    // this before loaded, but we'll handle it anyway.
                    return undefined;
                  }
                  throw error;
                })
                .then(
                  () => {
                    onLoaded();

                    if (!state.isDestroyed()) {
                      this.trigger('initSuccess');
                      this.trigger('loaded', this);
                    }
                  }, (err) => {
                    logging.error(`OT.Publisher.publish failed to bind video: ${err}`);
                    onLoadFailure(err);
                  }
                );
            }
          );
      });

      return this;
    };

    this._setScalableValues = async (scalableParam, scalableValues) => {
      const senders = await getAllPeerConnections().then(peerConnections =>
        peerConnections[0].getSenders().filter(({ track: { kind } }) => kind === 'video')
      );

      const [sender] = senders;
      const sendParameters = sender.getParameters();
      sendParameters.encodings.forEach((encoding, index) => {
        encoding[scalableParam] = scalableValues[index]; // eslint-disable-line no-param-reassign
      });

      await sender.setParameters(sendParameters);
    };

    this._setScalableFramerates = async (frameRates) => {
      const framerateValues = normalizeScalableValues(frameRates);
      if (framerateValues && areValidFramerates(framerateValues)) {
        await this._setScalableValues('maxFramerate', framerateValues);
      }
    };

    this._setScalableVideoLayers = async (videoLayers) => {
      const videoLayerValues = normalizeScalableValues(videoLayers);
      if (videoLayerValues && areValidResolutionScales(videoLayerValues)) {
        await this._setScalableValues('scaleResolutionDownBy', videoLayerValues);
      }
    };

    const areValidFramerates = (framerates) => {
      let previousFps = 0;
      // Only 15 and 30 fps are valid values and it cannot decrease when upscaling resolutions
      return framerates.every((fps) => {
        if ((fps !== 15 && fps !== 30) || fps < previousFps) {
          return false;
        }
        previousFps = fps;
        return true;
      });
    };

    const areValidResolutionScales = (scales) => {
      // Maximum to downscale is 16 so previous scale should not be equal or greater to 17
      let previousScale = 17;
      return scales.every((scale) => {
        // Only downscale values; i.e. <= 1 means to upscale, which is not valid
        if ((scale < 1) || scale >= previousScale) {
          return false;
        }
        previousScale = scale;
        return true;
      });
    };

    const normalizeScalableValues = (scalableValues) => {
      let normalizedValues;
      // API only accepts a colon separated value string
      if (typeof scalableValues !== 'string') {
        return normalizedValues;
      }
      const scalableValuesArr = scalableValues.split(':');
      // It cannot be empty nor larger than 3 values (HD, VGA and QVGA)
      if (scalableValuesArr.length === 0 || scalableValuesArr.length > 3) {
        return normalizedValues;
      }
      if (!scalableValuesArr.every(value => !isNaN(value))) {
        return normalizedValues;
      }
      normalizedValues = scalableValuesArr.map(value => parseInt(value, 10)).reverse();
      return normalizedValues;
    };


    const haveWorkingTracks = type => webRTCStream &&
    webRTCStream[`get${capitalize(type)}Tracks`]().length > 0 &&
    webRTCStream[`get${capitalize(type)}Tracks`]().every(track => track.readyState !== 'ended');

    const updateAudio = (activeReason) => {
      const shouldSendAudio = haveWorkingTracks('audio') && properties.publishAudio;

      if (chromeMixin) {
        chromeMixin.setMuted(!shouldSendAudio);
      }

      if (microphone) {
        microphone.muted(!shouldSendAudio);
      }

      refreshAudioVideoUI(activeReason);
    };

    /**
    * Starts publishing audio (if it is currently not being published)
    * when the <code>value</code> is <code>true</code>; stops publishing audio
    * (if it is currently being published) when the <code>value</code> is <code>false</code>.
    *
    * @param {Boolean} value Whether to start publishing audio (<code>true</code>)
    * or not (<code>false</code>).
    *
    * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
    * @see StreamPropertyChangedEvent
    * @method #publishAudio
    * @memberOf Publisher
    */
    this.publishAudio = (value) => {
      logAnalyticsEvent('publishAudio', 'Attempt', { publishAudio: value });
      properties.publishAudio = value;
      try {
        updateAudio();
        logAnalyticsEvent('publishAudio', 'Success', { publishAudio: value });
      } catch (e) {
        logAnalyticsEvent('publishAudio', 'Failure', { message: e.message });
      }
      return this;
    };

    let updateVideoSenderParametersSentinel;

    // keeps track of if the client has called mediaStreamTrack.stop(), so that we don't restart
    // the camera if they then call publishVideo(true)
    let isTrackManuallyStopped = false;

    const updateVideo = () => {
      const shouldSendVideo = haveWorkingTracks('video') && properties.publishVideo;
      if (env.name === 'Chrome' && env.version >= 69) {
        (async () => {
          if (updateVideoSenderParametersSentinel) {
            updateVideoSenderParametersSentinel.cancel();
          }
          updateVideoSenderParametersSentinel = new Cancellation();
          const executionSentinel = updateVideoSenderParametersSentinel;
          const peerConnections = await getAllPeerConnections();
          if (!executionSentinel.isCanceled()) {
            // only proceed if we weren't canceled during the async operation above
            peerConnections.forEach((peerConnection) => {
              setEncodersActiveState(peerConnection, shouldSendVideo);
            });
          }
        })();
      }

      if (webRTCStream) {
        webRTCStream.getVideoTracks().forEach((track) => {
          track.enabled = shouldSendVideo; // eslint-disable-line no-param-reassign
          if (track.isCreatedCanvas) {
            // eslint-disable-next-line no-param-reassign
            track.enabled = false;
          }
        });
      }

      refreshAudioVideoUI();
    };

    const destroyMediaProcessor = async () => {
      // Since no filtering is being applied, we perform some cleanup.  We
      // stop the original video track here since it's not being used
      // anymore -- this also turns off the camera LED
      mediaProcessor.getOriginalVideoTrack().stop();
      await mediaProcessor.destroy();
    };

    let currentDeviceId;
    let currentVideoFilter;

    this._toggleVideo = blockCallsUntilComplete(async (shouldHaveVideo) => {
      // we don't need to worry about the camera if we're screensharing or using
      // a custom video track
      // if we add support for switching between screen and camera this may cause issues
      if (isScreenSharing || isCustomVideoTrack || isTrackManuallyStopped) {
        return;
      }

      const oldTrack = getCurrentTrack();
      if (!oldTrack) {
        throw otError(
          Errors.NOT_SUPPORTED,
          new Error('Publisher._toggleVideo cannot toggleVideo when you have no video source.')
        );
      }

      // this will only have occured in they edge case that the client calls track.stop()
      if (oldTrack.readyState === 'ended') {
        isTrackManuallyStopped = true;
        return;
      }
      // create a canvas and grab the track from it to pass into video
      // resize the canvas so that we don't emit a 'streamPropertyChanged' event
      const { videoDimensions = getVideoDimensions() } = properties;
      let canvasTrack;
      try {
        canvasTrack = createCanvasVideoTrack(videoDimensions);
      } catch (err) {
        // if they don't support canvas.captureStream() we will just enable/disable as normal
        return;
      }

      const vidDevices = await getVideoDevices();
      if (shouldHaveVideo && OTHelpers.env.isAndroid && OTHelpers.env.isChrome) {
        // On Chrome on Android you need to stop the previous video track OPENTOK-37206
        if (oldTrack && oldTrack.stop) {
          oldTrack.stop();
        }
      }

      // store the current deviceId to reacquire the video later
      if (!shouldHaveVideo) {
        try {
          currentDeviceId = vidDevices.find(device => device.label === oldTrack.label).deviceId;
        } catch (err) {
          // Just suppress...let's just use previous currentDeviceId
        }

        const videoFilter = mediaProcessor.getVideoFilter();

        if (videoFilter) {
          // Save the current video filter because we want to make sure it
          // gets enabled when the user publishes video again
          currentVideoFilter = videoFilter;

          await destroyMediaProcessor();
        }
      }

      if (currentDeviceId &&
        vidDevices.findIndex(device => device.deviceId === currentDeviceId) === -1) {
        throw otError(
          Errors.NO_DEVICES_FOUND,
          new Error('Previous device no longer available - deviceId not found')
        );
      }

      privateEvents.emit('streamDestroy');
      let newTrack = canvasTrack;

      if (shouldHaveVideo) {
        newTrack = await getTrackFromDeviceId(currentDeviceId);

        if (currentVideoFilter) {
          await mediaProcessor.setVideoFilter(currentVideoFilter);
          await mediaProcessor.setMediaStream(webRTCStream);
          newTrack = await mediaProcessor.setVideoTrack(newTrack);
        }
      }

      try {
        await replaceTrackAndUpdate(oldTrack, newTrack);
      } catch (err) {
        throw err;
      }
    });

    /**
    * Starts publishing video (if it is currently not being published)
    * when the <code>value</code> is <code>true</code>; stops publishing video
    * (if it is currently being published) when the <code>value</code> is <code>false</code>.
    *
    * @param {Boolean} value Whether to start publishing video (<code>true</code>)
    * or not (<code>false</code>).
    *
    * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
    * @see StreamPropertyChangedEvent
    * @method #publishVideo
    * @memberOf Publisher
    */
    this.publishVideo = (value) => {
      logAnalyticsEvent('publishVideo', 'Attempt', { publishVideo: value });
      properties.publishVideo = value;
      try {
        this._toggleVideo(properties.publishVideo);
        updateVideo();
        logAnalyticsEvent('publishVideo', 'Success', { publishVideo: value });
      } catch (e) {
        logAnalyticsEvent('publishVideo', 'Failure', { message: e.message });
      }
      return this;
    };


    /**
    * Sets the content hint for the video track of the publisher's stream. This allows browsers
    * to use encoding or processing methods more appropriate to the type of content.
    * <p>
    * Use this method to change the video content hit dynamically. Set the initial video content
    * hit by setting the <code>videoContentHint</code> property of the options passed into the
    * <a href="OT.html#initPublisher">OT.initPublisher()</a> method.
    * <p>
    * Chrome 60+, Safari 12.1+, Edge 79+, and Opera 47+ support video content hints.
    *
    * @param {String} videoContentHint You can set this to one of the following values:
    *    <p>
    *    <ul>
    *      <li>
    *        <code>""</code> &mdash; No hint is provided.
    *      </li>
    *      <li>
    *        <code>"motion"</code> &mdash; The track should be treated as if it contains video
    *        where motion is important.
    *      </li>
    *      <li>
    *        <code>"detail"</code> &mdash; The track should be treated as if video details
    *        are extra important.
    *      </li>
    *      <li>
    *        <code>"text"</code> &mdash; The track should be treated as if text details are
    *        extra important.
    *      </li>
    *    </ul>
    *
    * @see <a href="#getVideoContentHint">Publisher.getVideoContentHint()</a>
    * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * @method #setVideoContentHint
    * @memberOf Publisher
    */
    this.setVideoContentHint = videoContentHint =>
      setVideoContentHint(webRTCStream, videoContentHint);

    /**
    * Returns the content hint for the video track.
    *
    * @return {String} One of the following values: <code>""</code>,
    *         <code>"motion"</code>, <code>"detail</code>, or <code>"text"</code>.
    * @see <a href="#setVideoContentHint">Publisher.setVideoContentHint()</a>
    * @method #getVideoContentHint
    * @memberOf Publisher
    */
    this.getVideoContentHint = () => getVideoContentHint(webRTCStream);

    /**
    * Deletes the Publisher object and removes it from the HTML DOM.
    * <p>
    * The Publisher object dispatches a <code>destroyed</code> event when the DOM
    * element is removed.
    * </p>
    * @method #destroy
    * @memberOf Publisher
    * @return {Publisher} The Publisher.
    */

    this.destroy = function (/* unused */ reason, quiet) {
      // @todo OPENTOK-36652 this.session should not be needed here
      if (state.isAttemptingToPublish() && this.session) {
        logConnectivityEvent('Cancel', { reason: 'destroy' });
      }

      if (state.isDestroyed()) { return this; }
      state.set('Destroyed');

      reset();

      if (processedOptions) {
        processedOptions.off();
        processedOptions = null;
      }

      if (chromeMixin) {
        chromeMixin.destroy();
        chromeMixin = null;
      }

      if (privateEvents) {
        privateEvents.off();
        privateEvents = null;
      }

      if (quiet !== true) {
        this.dispatchEvent(new Events.DestroyedEvent(
          eventNames.PUBLISHER_DESTROYED,
          this,
          reason
        ));
      }

      this.off();

      return this;
    };

    /*
    * @methodOf Publisher
    * @private
    */
    this.disconnect = () => {
      Object.keys(peerConnectionsAsync)
        .forEach((peerConnectionId) => {
          const futurePeerConnection = getPeerConnectionById(peerConnectionId);
          delete peerConnectionsAsync[peerConnectionId];
          futurePeerConnection.then(peerConnection => this._removePeerConnection(peerConnection));
        });
    };

    this.processMessage = (type, fromConnectionId, message) => {
      const subscriberId = get(message, 'params.subscriber', fromConnectionId)
        .replace(/^INVALID-STREAM$/, fromConnectionId);
      const peerId = get(message, 'content.peerId');
      const sourceStreamId = get(message, 'content.sourceStreamId', 'MANTIS');

      // Symphony will not have a subscriberId so we'll fallback to using the connectionId for it.
      // Also fallback to the connectionId if it is equal to 'INVALID-STREAM' (See OPENTOK-30029).
      const peerConnectionId = `${subscriberId}~${peerId}~${sourceStreamId}`;
      logging.debug(`OT.Publisher.processMessage: Received ${type} from ${fromConnectionId} for ${peerConnectionId}`);
      logging.debug(message);

      const futurePeerConnection = getPeerConnectionById(peerConnectionId);
      const addPeerConnection = () => {
        const send = createSendMethod({
          socket: this.session._.getSocket(),
          uri: message.uri,
          content: {
            peerId,
            sourceStreamId,
          },
        });

        const log = (action, variation, payload, logOptions = {}, throttle) => {
          const transformedOptions = {
            peerId,
            sourceStreamId: getMediaModeBySourceStreamId(sourceStreamId),
            ...logOptions,
          };
          return logAnalyticsEvent(action, variation, payload, transformedOptions, throttle);
        };

        const logQoS = (qos) => {
          // We only log data from the active peer connection
          if (sourceStreamId !== activeSourceStreamId) {
            return;
          }

          recordQOS({
            ...qos,
            peerId,
            remoteConnectionId: fromConnectionId,
            sourceStreamId,
          });
        };

        createPeerConnection({
          peerConnectionId,
          send,
          log,
          logQoS,
          sourceStreamId,
        })
          .then((peerConnection) => {
            setPeerConnectionMeta(peerConnection, {
              remoteConnectionId: fromConnectionId,
              remoteSubscriberId: subscriberId,
              peerId,
              sourceStreamId,
              peerConnectionId,
            });

            peerConnection.processMessage(type, message);

            // Allow this runaway promise
            // http://bluebirdjs.com/docs/warning-explanations.html#warning-a-promise-was-created-in-a-handler-but-was-not-returned-from-it
            return null;
          })
          .catch((err) => {
            logging.error('OT.Publisher failed to create a peerConnection', err);
          });
      };
      const { isAdaptiveEnabled } = this.session.sessionInfo;
      switch (type) {
        case 'unsubscribe':
          this._removeSubscriber(subscriberId);
          break;
        default:
          if (!futurePeerConnection) {
            if (isAdaptiveEnabled && getP2pPeerConnection()) {
              // In the case Rumor sends two generateOffers for the P2P leg,
              // we need to ignore the second one.
              return;
            }
            addPeerConnection();
          } else {
            futurePeerConnection.then(
              peerConnection => peerConnection.processMessage(type, message)
            );
          }
          break;
      }
    };

    /**
    * Returns the base-64-encoded string of PNG data representing the Publisher video.
    *
    *   <p>You can use the string as the value for a data URL scheme passed to the src parameter of
    *   an image file, as in the following:</p>
    *
    * <pre>
    *  var imgData = publisher.getImgData();
    *
    *  var img = document.createElement("img");
    *  img.setAttribute("src", "data:image/png;base64," + imgData);
    *  var imgWin = window.open("about:blank", "Screenshot");
    *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
    *  imgWin.document.body.appendChild(img);
    * </pre>
    *
    * @method #getImgData
    * @memberOf Publisher
    * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
    */

    this.getImgData = function () {
      if (!loaded) {
        logging.error(
          'OT.Publisher.getImgData: Cannot getImgData before the Publisher is publishing.'
        );

        return null;
      }

      const video = widgetView && widgetView.video();
      return video ? video.imgData() : null;
    };

    const setNewStream = (newStream) => {
      cleanupLocalStream();
      webRTCStream = newStream;
      privateEvents.emit('streamChange');
      microphone = new Microphone(webRTCStream, !properties.publishAudio);
    };

    const defaultReplaceTrackLogic = (peerConnection) => {
      peerConnection.getSenders().forEach((sender) => {
        if (sender.track.kind === 'audio' && webRTCStream.getAudioTracks().length) {
          return sender.replaceTrack(webRTCStream.getAudioTracks()[0]);
        } else if (sender.track.kind === 'video' && webRTCStream.getVideoTracks().length) {
          return sender.replaceTrack(webRTCStream.getVideoTracks()[0]);
        }
        return undefined;
      });
    };

    const replaceTracks = (replaceTrackLogic = defaultReplaceTrackLogic) => (
      getAllPeerConnections().then((peerConnections) => {
        const tasks = [];
        peerConnections.map(replaceTrackLogic);
        return Promise.all(tasks);
      })
    );

    {
      let videoIndex = 0;

      const cycleVideo = async () => {
        if (OTHelpers.env.isLegacyEdge || !windowMock.RTCRtpSender || typeof windowMock.RTCRtpSender.prototype.replaceTrack !== 'function') {
          throw otError(
            Errors.UNSUPPORTED_BROWSER,
            new Error('Publisher#cycleVideo is not supported in your browser.'),
            ExceptionCodes.UNABLE_TO_PUBLISH
          );
        }

        if (isCustomVideoTrack || isScreenSharing) {
          throw otError(
            Errors.NOT_SUPPORTED,
            new Error('Publisher#cycleVideo: The publisher is not using a camera video source')
          );
        }

        const oldTrack = getCurrentTrack();
        if (!oldTrack) {
          throw otError(
            Errors.NOT_SUPPORTED,
            new Error('Publisher#cycleVideo cannot cycleVideo when you have no video source.')
          );
        }

        videoIndex += 1;

        const vidDevices = await getVideoDevices();

        // different devices return the cameras in different orders
        const hasOtherVideoDevices =
              vidDevices.filter(device => (device.label !== oldTrack.label)).length > 0;
        if (hasOtherVideoDevices) {
          while (vidDevices[videoIndex % vidDevices.length].label === oldTrack.label) {
            videoIndex += 1;
          }
        }

        privateEvents.emit('streamDestroy');

        const newVideoDevice = vidDevices[videoIndex % vidDevices.length];
        const deviceId = newVideoDevice.deviceId;

        await attemptToSetVideoTrack(deviceId);

        return currentDeviceId;
      };

      /**
      * Switches the video input source used by the publisher to the next one in the list
      * of available devices.
      * <p>
      * This will result in an error (the Promise returned by the method is rejected) in the
      * following conditions:
      * <ul>
      *   <li>
      *     The user denied access to the video input device.
      *   </li>
      *   <li>
      *     The publisher is not using a camera video source. (The <code>videoSource</code>
      *     option of the <a href="OT.html#initPublisher">OT.initPublisher()</a> method was
      *     set to <code>null</code>, <code>false</code>, a MediaStreamTrack object, or
      *     <code>"screen"</code>).
      *   </li>
      *   <li>
      *     There are no video input devices (cameras) available.
      *   </li>
      *   <li>
      *     There was an error acquiring video from the video input device.
      *   </li>
      *  </ul>
      * </p>
      *
      * @method #cycleVideo
      * @memberOf Publisher
      *
      * @return {Promise} A promise that resolves when the operation completes
      * successfully. The promise resolves with an object that has a
      * <code>deviceId</code> property set to the device ID of the camera used:
      *
      * <pre>
      *   publisher.cycleVideo().then(console.log);
      *   // Output: {deviceId: "967a86e52..."}
      * </pre>
      *
      * If there is an error, the promise is rejected.
      *
      * @see <a href="#setVideoSource">Publisher.setVideoSource()</a>
      */
      this.cycleVideo = blockCallsUntilComplete(async () => {
        let deviceId;

        try {
          deviceId = await cycleVideo();
        } catch (err) {
          logging.error(`Publisher#cycleVideo: could not cycle video: ${err}`);
          throw err;
        }

        return { deviceId };
      });
    }

    const replaceTrackAndUpdate = async (oldTrack, newTrack) => {
      const pcs = await getAllPeerConnections();
      await Promise.all(pcs.map(pc => pc.findAndReplaceTrack(oldTrack, newTrack)));
      webRTCStream.addTrack(newTrack);
      webRTCStream.removeTrack(oldTrack);
      if (oldTrack && oldTrack.stop) {
        let isNewTrackFiltered;

        // We "try" since this expression will throw in browsers that don't
        // support this API.
        try {
          isNewTrackFiltered = newTrack instanceof MediaStreamTrackGenerator;
        } catch (err) {
          isNewTrackFiltered = false;
        }

        // The oldTrack is being used as input by the MediaProcessor, so stopping
        // it will stop the newTrack as well.
        if (!isNewTrackFiltered) {
          oldTrack.stop();
        }
      }

      if (OTHelpers.env.name === 'Firefox' || OTHelpers.env.name === 'Safari') {
        // Local video freezes on old stream without this for some reason
        this.videoElement().srcObject = null;
        this.videoElement().srcObject = webRTCStream;
      }

      const video = widgetView && widgetView.video();
      if (video) {
        video.refreshTracks();
      }

      privateEvents.emit('streamChange');
      updateVideo();
    };

    const getTrackFromDeviceId = async (deviceId) => {
      const newOptions = cloneDeep(options);
      newOptions.audioSource = null;
      newOptions.videoSource = deviceId;
      processedOptions = processPubOptions(
        newOptions,
        'OT.Publisher.getTrackFromDeviceId',
        () => (state && state.isDestroyed())
      );
      processedOptions.on({
        accessDialogOpened: onAccessDialogOpened,
        accessDialogClosed: onAccessDialogClosed,
      });
      const {
        getUserMedia: getUserMediaHelper,
      } = processedOptions;
      let newVideoStream;
      try {
        newVideoStream = await getUserMediaHelper();
      } catch (err) {
        logging.error(err);
        // TODO We may want to consider bubbling up the err here
      }
      return newVideoStream && newVideoStream.getVideoTracks()[0];
    };


    const getCurrentTrack = () => {
      const [currentTrack] = webRTCStream.getVideoTracks();
      return currentTrack;
    };

    const getVideoDevices = async () => {
      const devices = await deviceHelpers.shouldAskForDevices();
      const vidDevices = devices.videoDevices;
      if (!devices.video || !vidDevices || !vidDevices.length) {
        throw otError(
          Errors.NO_DEVICES_FOUND,
          new Error('No video devices available'),
          ExceptionCodes.UNABLE_TO_PUBLISH
        );
      }
      return vidDevices;
    };

    const replaceAudioTrack = (oldTrack, newTrack) => {
      if (newTrack) {
        webRTCStream.addTrack(newTrack);
      }
      if (oldTrack) {
        webRTCStream.removeTrack(oldTrack);
      }

      const video = widgetView && widgetView.video();
      if (video) {
        video.refreshTracks();
      }

      if (chromeMixin) {
        if (newTrack && !oldTrack) {
          chromeMixin.addAudioTrack();
        }
        if (oldTrack && !newTrack) {
          chromeMixin.removeAudioTrack();
        }
      }

      if (oldTrack && oldTrack.stop) {
        oldTrack.stop();
      }

      if (newTrack) {
        // Turn the audio back on if the audio track stopped because it was disconnected
        updateAudio();
        microphone = new Microphone(webRTCStream, !properties.publishAudio);
      }
      privateEvents.emit('streamChange');
      refreshAudioVideoUI();
    };

    const resetAudioSource = async (audioTrack) => {
      const audioDeviceId = audioTrack.getSettings().deviceId;
      try {
        await this.setAudioSource(audioDeviceId);
        // We need to add the onmute listener to the new audio track.
        const newAudioTrack = webRTCStream.getAudioTracks()[0];
        if (newAudioTrack) {
          newAudioTrack.onmute = () => (handleBuggedMutedLocalAudioTrack(newAudioTrack));
          newAudioTrack.onunmute = () => (handleBuggedUnMutedLocalAudioTrack(newAudioTrack));
        }
      } catch (err) {
        logging.error(err);
      }
    };

    // this should be called when we detect a mute event from a bugged device
    const handleBuggedMutedLocalAudioTrack = (audioTrack) => {
      let shouldRePublishVideo = false;
      if (properties.publishVideo && document.hidden) {
        shouldRePublishVideo = true;
        // turning the video off to prevent that videotrack is ended
        this.publishVideo(false);
      }
      // trigger the handler onVisibilityChange
      const visibilityHandler = async () => {
        if (!document.hidden) {
          await resetAudioSource(audioTrack);
          if (shouldRePublishVideo) {
            this.publishVideo(true);
          }
          document.removeEventListener('visibilitychange', visibilityHandler);
        }
      };
      document.addEventListener('visibilitychange', visibilityHandler);
    };

    const handleBuggedUnMutedLocalAudioTrack = (audioTrack) => {
      if (hasAudio()) {
        if (!hasVideo()) {
          // We only need to reset the audio source when the publisher is audio only.
          resetAudioSource(audioTrack);
        } else {
          // Inconsistenly the publisher shows a black frame after the incoming call with compact UI
          // ends. In order to unblock the video element we added this hack that needs to be
          // revisited.
          // We need to call pause and play again, and since we have a listener on the onpause event
          // that internally calls the play function, we only need to call pause(),
          // and the play will be automatically executed.
          this.videoElement().pause();
        }
      }
      this.session.trigger('gsmCallEnded');
    };


    /**
    * Switches the audio input source used by the publisher. You can set the
    * <code>audioSource</code> to a device ID (string) or audio MediaStreamTrack object.
    * <p>
    * This will result in an error (the Promise returned by the method is rejected) in the
    * following conditions:
    * <ul>
    *   <li>
    *     The browser does not support this method. This method is not supported in
    *     Internet Explorer or non-Chromium versions of Edge (older than version 79).
    *   </li>
    *   <li>
    *     The publisher was not initiated with an audio source. (The <code>audioSource</code>
    *     option of the <a href="OT.html#initPublisher">OT.initPublisher()</a> method was
    *     set to <code>null</code> or <code>false</code>).
    *   </li>
    *   <li>
    *     The user denied access to the audio input device.
    *   </li>
    *   <li>
    *     There was an error acquiring audio from the audio input device or MediaStreamTrack
    *     object.
    *   </li>
    *   <li>
    *     The <code>audioSource</code> value is not a string or MediaStreamTrack object.
    *   </li>
    *   <li>
    *     The <code>audioSource</code> string is not a valid audio input device available
    *     to the browser.
    *   </li>
    *  </ul>
    * </p>
    *
    * @param {Object} audioSource The device ID (string) of an audio input device, or an audio
    * MediaStreamTrack object.
    *
    * @method #setAudioSource
    * @memberOf Publisher
    *
    * @see <a href="#getAudioSource">Publisher.getAudioSource()</a>
    *
    * @return {Promise} A promise that resolves when the operation completes successfully.
    * If there is an error, the promise is rejected.
    */
    let cancelPreviousSetAudioSourceSentinel;

    const setAudioSource = async (audioSource) => {
      const CANCEL_ERR_MSG = 'Operation did not succeed due to a new request.';
      if (cancelPreviousSetAudioSourceSentinel) {
        cancelPreviousSetAudioSourceSentinel.cancel();
      }
      cancelPreviousSetAudioSourceSentinel = new Cancellation();
      const currentCancelSentinel = cancelPreviousSetAudioSourceSentinel;

      const setStreamIfNotCancelled = (stream) => {
        if (currentCancelSentinel.isCanceled()) {
          stream.getTracks(track => track.stop());
          throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
        }
        return setAudioSource(stream.getAudioTracks()[0]);
      };

      if (OTHelpers.env.isLegacyEdge || !windowMock.RTCRtpSender || typeof windowMock.RTCRtpSender.prototype.replaceTrack !== 'function') {
        throw otError(
          Errors.UNSUPPORTED_BROWSER,
          new Error('Publisher#setAudioSource is not supported in your browser.')
        );
      }
      const prevAudioSource = this.getAudioSource();
      if (!prevAudioSource) {
        // We are adding an audio track where there wasn't one before
        throw otError(
          Errors.NOT_SUPPORTED,
          new Error('Publisher#setAudioSource cannot add an audio source when you started without one.')
        );
      }
      if (audioSource instanceof MediaStreamTrack) {
        const pcs = await getAllPeerConnections();
        if (currentCancelSentinel.isCanceled()) {
          throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
        }
        await Promise.all(pcs.map(pc => pc.findAndReplaceTrack(prevAudioSource, audioSource)));
        // we don't cancel here on purpose, the next step is required.
        return replaceAudioTrack(prevAudioSource, audioSource);
      } else if (typeof audioSource === 'string') {
        // Must be a deviceId, call getUserMedia and get the MediaStreamTrack
        const newOptions = cloneDeep(options);
        newOptions.audioSource = audioSource;
        newOptions.videoSource = null;
        processedOptions = processPubOptions(
          newOptions,
          'OT.Publisher.setAudioSource',
          () => currentCancelSentinel.isCanceled() || (state && state.isDestroyed())
        );
        processedOptions.on({
          accessDialogOpened: onAccessDialogOpened,
          accessDialogClosed: onAccessDialogClosed,
        });
        const prevLabel = prevAudioSource.label;
        const prevDeviceId = (
          prevAudioSource.getConstraints && prevAudioSource.getSettings().deviceId
        ) || undefined;
        // In firefox we have to stop the previous track before we get a new one
        if (prevAudioSource) {
          prevAudioSource.stop();
        }
        const { getUserMedia: getUserMediaHelper } = processedOptions;
        try {
          return await setStreamIfNotCancelled(await getUserMediaHelper());
        } catch (err) {
          // oh no, the new stream did not work out, let's try to get back the old
          // audio device.
          if (currentCancelSentinel.isCanceled()) {
            throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
          }
          const prevOptions = cloneDeep(options);
          prevOptions.videoSource = null;
          prevOptions.audioSource = prevDeviceId;
          if (!prevOptions.audioSource && prevLabel) {
            const previousDevice = (await getInputMediaDevices())
              .find(x => x.label === prevLabel);

            if (currentCancelSentinel.isCanceled()) {
              throw otError(Errors.CANCEL, new Error(CANCEL_ERR_MSG));
            }

            if (previousDevice) {
              prevOptions.audioSource = previousDevice.deviceId;
            }
          }

          if (!prevOptions.audioSource) {
            err.message += ' (could not determine previous audio device)';
            throw otError(Errors.NOT_FOUND, err);
          }

          processedOptions = processPubOptions(
            prevOptions,
            'OT.Publisher.setAudioSource',
            () => currentCancelSentinel.isCanceled() || (state && state.isDestroyed())
          );


          const stream = await processedOptions.getUserMedia().catch((error) => {
            // eslint-disable-next-line no-param-reassign
            error.message += ' (could not obtain previous audio device)';
            throw error;
          });

          await setStreamIfNotCancelled(stream);

          err.message += ' (reverted to previous audio device)';
          throw err;
        }
      } else {
        throw otError(
          Errors.INVALID_PARAMETER,
          new Error('Invalid parameter passed to OT.Publisher.setAudioSource(). Expected string or MediaStreamTrack.')
        );
      }
    };

    this.setAudioSource = setAudioSource;

    /**
    * Returns the MediaStreamTrack object used as the audio input source for the publisher.
    * If the publisher does not have an audio source, this method returns null.
    *
    * @method #getAudioSource
    * @memberOf Publisher
    * @see <a href="#setAudioSource">Publisher.setAudioSource()</a>
    *
    * @return {MediaStreamTrack} The audio source for the publisher (or null, if there is none).
    */
    this.getAudioSource = () => {
      if (webRTCStream && webRTCStream.getAudioTracks().length > 0) {
        return webRTCStream.getAudioTracks()[0];
      }
      return null;
    };

    /**
     * This method sets the video source for a publisher that is using a camera.
     * Pass in the device ID of the new video source.
     *
     * <p>
     * The following will result in errors:
     *
     * <ul>
     * <li>If the <code>videoSourceId</code> parameter is not a string
     * or the device ID for a valid video input device, the promise
     * will reject with an error with the <code>name</code> property
     * set to <code>'OT_INVALID_VIDEO_SOURCE'</code>.
     * </li>
     *
     * <li>If the publisher does not currently use a camera input, the promise
     * will reject with an error with the <code>name</code> property
     * set to <code>'OT_SET_VIDEO_SOURCE_FAILURE'</code>.
     * </li>
     * </ul>
     *
     * @param {String} videoSourceId The device ID of a video input (camera) device.
     * @method #setVideoSource
     * @memberOf Publisher
     *
     * @see <a href="OT.html#getDevices">OT.getDevices()</a>
     * @see <a href="#getVideoSource">Publisher.getVideoSource()</a>
     * @see <a href="#cycleVideo">Publisher.cycleVideo()</a>
     *
     * @return {Promise} A promise that resolves with no value when the operation
     * completes successfully. If there is an error, the promise is rejected.
     */
    const setVideoSource = async function setVideoSource(videoSourceId) {
      const invalidVideoSourceOtError = otError(
        Errors.INVALID_VIDEO_SOURCE,
        new Error('Invalid video source. Video source must be a valid video input deviceId'),
        1041
      );

      const setVideoSourceOtError = otError(
        Errors.SET_VIDEO_SOURCE_FAILURE,
        new Error('You cannot reset the video source on a publisher that does not currently use a camera source.'),
        1040
      );
      if (OTHelpers.env.isLegacyEdge || !windowMock.RTCRtpSender || typeof windowMock.RTCRtpSender.prototype.replaceTrack !== 'function') {
        throw otError(
          Errors.UNSUPPORTED_BROWSER,
          new Error('setVideoSource is not supported in your browser.'),
          ExceptionCodes.UNABLE_TO_PUBLISH
        );
      }

      // check for validity of input and publisher
      if (typeof videoSourceId !== 'string') {
        throw invalidVideoSourceOtError;
      }

      // we can't use hasVideo because that only checks if the video is
      const isAudioOnly = !webRTCStream || webRTCStream.getVideoTracks().length === 0;

      if (isCustomVideoTrack || isScreenSharing || isAudioOnly) {
        throw setVideoSourceOtError;
      }

      const deviceList = await getInputMediaDevices();
      const isValidVideoDeviceId = deviceList.find(device =>
        device.kind === 'videoInput' && device.deviceId === videoSourceId
      );
      if (!isValidVideoDeviceId) {
        throw invalidVideoSourceOtError;
      }

      await attemptToSetVideoTrack(videoSourceId);
    };

    this.setVideoSource = setVideoSource;

    const attemptToSetVideoTrack = async (newVideoDeviceId) => {
      const oldDeviceID = currentDeviceId;
      currentDeviceId = newVideoDeviceId;

      // We shouldn't replace the track unless the video is on
      if (!properties.publishVideo) {
        return;
      }

      const oldTrack = getCurrentTrack();
      if (
        properties.publishVideo &&
        OTHelpers.env.isAndroid &&
        (OTHelpers.env.isChrome || OTHelpers.env.isFirefox)
      ) {
        // On Chrome on Android you need to stop the previous video track OPENTOK-37206
        // In case we are not publishing video, we don't need to stop the oldTrack since
        // there isn't going to be a new track, once we publish video again, the oldTrack
        // will be properly stopped in _toggleVideo
        if (oldTrack && oldTrack.stop) {
          oldTrack.stop();
        }
      }

      let newVideoTrack;

      try {
        newVideoTrack = await getTrackFromDeviceId(newVideoDeviceId);
      } catch (err) {
        currentDeviceId = oldDeviceID;
        logging.error(err);
        return;
      }

      if (!newVideoTrack) {
        // eslint-disable-next-line no-console
        console.warn('Unable to aquire video track. Moving to next device.');
        return;
      }

      if (currentVideoFilter) {
        newVideoTrack = await mediaProcessor.setVideoTrack(newVideoTrack);
      }

      await replaceTrackAndUpdate(oldTrack, newVideoTrack);

      if (properties.publishVideo) {
        isTrackManuallyStopped = false;
      }
    };

    /**
    * Returns an object containing properties defining the publisher's current
    * video source.
    *
    * @method #getVideoSource
    * @memberOf Publisher
    * @see <a href="#setVideoSource">Publisher.setVideoSource()</a>
    * @see <a href="#setVideoSource">OT.initPublisher()</a>
    *
    * @return {VideoSource} The return object has the following properties:
    *
    * <p>
    * <ul>
    *   <li><code>deviceId</code> (String | null) &mdash; The device ID.</li>
    *   <li><code>type</code> (String | null) &mdash; This is set to
    *   'camera' (for a  camera-based video), 'screen' (for a screen-sharing video),
    *   or 'custom' (for a video  with a MediaStreamTrack source). </li>
    *   <li><code>track</code> (MediaStreamTrack | null) &mdash; The
    *   MediaStreamTrack for the video.</li>
    * </ul>
    * <p>
    * Any inapplicable properties will be set to null.
    */

    this.getVideoSource = () => {
      const sourceProperties = {};
      const isAudioOnly = !webRTCStream || webRTCStream.getVideoTracks().length === 0;

      sourceProperties.track = ((webRTCStream && properties.publishVideo)
       && webRTCStream.getVideoTracks()[0]) || null;

      sourceProperties.deviceId = (!isScreenSharing && !isCustomVideoTrack && currentDeviceId)
        ? currentDeviceId : null;

      if (isCustomVideoTrack) {
        sourceProperties.type = 'custom';
      } else if (isScreenSharing) {
        sourceProperties.type = 'screen';
      } else if (!isAudioOnly) {
        sourceProperties.type = 'camera';
      } else {
        sourceProperties.type = null;
      }

      return sourceProperties;
    };

    // API Compatibility layer for Flash Publisher, this could do with some tidyup.

    this._ = {
      publishToSession: (session, analyticsReplacement) => {
        if (analyticsReplacement) {
          analytics = analyticsReplacement;
        }
        // Add session property to Publisher
        previousSession = session;
        this.session = session;

        const requestedStreamId = uuid();
        lastRequestedStreamId = requestedStreamId;
        this.streamId = requestedStreamId;

        logConnectivityEvent('Attempt', {
          dataChannels: properties.channels,
          properties: whitelistPublisherProperties(properties),
        });

        const loadedPromise = new Promise((resolve, reject) => {
          if (loaded) {
            resolve();
            return;
          }

          this.once('initSuccess', resolve);
          this.once('destroyed', ({ reason }) => {
            let reasonDescription = '';
            if (reason) {
              reasonDescription = ` Reason: ${reason}`;
            }
            reject(new Error(
              `Publisher destroyed before it finished loading.${reasonDescription}`
            ));
          });
        });

        logging.debug('publishToSession: waiting for publishComplete, which is triggered by ' +
          'stream#created from rumor');

        const completedPromise = new Promise((resolve, reject) => {
          this.once('publishComplete', (error) => {
            if (error) {
              reject(error);
              return;
            }
            this._setScalableFramerates(properties.scalableFramerates);
            this._setScalableVideoLayers(properties.scalableVideoLayers);

            logging.debug('publishToSession: got publishComplete');

            resolve();
          });
        });

        const processMessagingError = (error) => {
          const publicError = createStreamErrorMap(error);
          logConnectivityEvent('Failure', {}, {
            failureReason: 'Publish',
            failureCode: publicError.code,
            failureMessage: publicError.message,
          });
          if (state.isAttemptingToPublish()) {
            this.trigger('publishComplete', publicError);
          }

          OTErrorClass.handleJsException({
            errorMsg: error.message,
            code: publicError.code,
            target: this,
            error,
            analytics,
          });

          throw publicError;
        };

        logging.debug('publishToSession: waiting for loaded');

        const streamCreatedPromise = loadedPromise
          .then(() => session._.getVideoCodecsCompatible(webRTCStream))
          .then((videoCodecsCompatible) => {
            logging.debug('publishToSession: loaded');
            // Bail if this.session is gone, it means we were unpublished
            // before createStream could finish.
            if (!this.session) { return undefined; }

            // make sure we trigger an error if we are not getting any "ack" after a reasonable
            // amount of time
            const publishGuardingTo = setTimeout(() => {
              onPublishingTimeout(session);
            }, PUBLISH_MAX_DELAY);

            this.once('publishComplete', () => {
              clearTimeout(publishGuardingTo);
            });

            state.set('PublishingToSession');

            const video = videoCodecsCompatible && widgetView && widgetView.video();
            const hasVideoTrack = webRTCStream.getVideoTracks().length > 0;
            const didRequestVideo = properties.videoSource !== null &&
              properties.videoSource !== false;
            if (video && hasVideoTrack && didRequestVideo) {
              streamChannels.push(new StreamChannel({
                id: 'video1',
                type: 'video',
                active: properties.publishVideo,
                orientation: VideoOrientation.ROTATED_NORMAL,
                frameRate: properties.frameRate,
                width: video.videoWidth(),
                height: video.videoHeight(),
                e2ee: isE2eeEnabled(),
                source: (() => {
                  if (isScreenSharing) {
                    return 'screen';
                  }
                  if (isCustomVideoTrack) {
                    return 'custom';
                  }
                  return 'camera';
                })(),
                fitMode: properties.fitMode,
              }));
            }

            const hasAudioTrack = webRTCStream.getAudioTracks().length > 0;
            const didRequestAudio = properties.audioSource !== null &&
              properties.audioSource !== false;


            // @todo should we just use hasAudioTrack here? if hasAudioTrack is true
            // then does it matter if didRequestAudio is false? we still have an audio
            // track for some reason!

            if (didRequestAudio && hasAudioTrack) {
              streamChannels.push(new StreamChannel({
                id: 'audio1',
                type: 'audio',
                active: properties.publishAudio,
              }));
            }

            logging.debug('publishToSession: creating rumor stream id');

            return new Promise((resolve, reject) => {
              _createStream(
                null,
                (messagingError, streamId, message) => {
                  if (messagingError) {
                    reject(processMessagingError(messagingError));
                    return;
                  }
                  resolve({ streamId, message });
                }
              );
            });
          })
          .then((maybeStream) => {
            if (maybeStream === undefined) {
              return;
            }

            const { streamId, message } = maybeStream;

            logging.debug('publishToSession: rumor stream id created:', streamId,
              '(this is different from stream#created, which requires media to actually be ' +
              'flowing for mantis sessions)');

            if (streamId !== requestedStreamId) {
              throw new Error('streamId response does not match request');
            }

            this.streamId = streamId;
            rumorIceServers = parseIceServers(message);
          })
          .catch((err) => {
            this.trigger('publishComplete', err);
            throw err;
          });

        return Promise.all([streamCreatedPromise, completedPromise]);
      },

      unpublishFromSession: (session, reason) => {
        if (!this.session || session.id !== this.session.id) {
          if (reason === 'unpublished') {
            const selfSessionText = (this.session && this.session.id) || 'no session';

            logging.warn(
              `The publisher ${guid} is trying to unpublish from a session ${session.id} it is not ` +
              `attached to (it is attached to ${selfSessionText})`
            );
          }

          return this;
        }

        if (session.isConnected() && (this.stream || state.isAttemptingToPublish())) {
          session._.streamDestroy(this.streamId);
        }
        streamCleanupJobs.releaseAll();

        // Disconnect immediately, rather than wait for the WebSocket to
        // reply to our destroyStream message.
        this.disconnect();
        if (state.isAttemptingToPublish()) {
          logConnectivityEvent('Cancel', { reason: 'unpublish' });

          const createErrorFromReason = () => {
            switch (reason) {
              case 'mediaStopped':
                return 'The video element fired the ended event, indicating there is an issue with the media';
              case 'unpublished':
                return 'The publisher was unpublished before it could be published';
              case 'reset':
                return 'The publisher was reset';
              default:
                return `The publisher was destroyed due to ${reason}`;
            }
          };

          const err = new Error(createErrorFromReason());

          this.trigger(
            'publishComplete',
            otError(
              reason === 'mediaStopped' ? Errors.MEDIA_ENDED : Errors.CANCEL,
              err
            )
          );
        }
        this.session = null;

        logAnalyticsEvent('unpublish', 'Success');

        this._.streamDestroyed(reason);

        return this;
      },

      unpublishStreamFromSession: (stream, session, reason) => {
        if (!lastRequestedStreamId || stream.id !== lastRequestedStreamId) {
          logging.warn(`The publisher ${guid} is trying to destroy a stream ${
            stream.id} that is not attached to it (it has ${
            lastRequestedStreamId || 'no stream'} attached to it)`);
          return this;
        }

        return this._.unpublishFromSession(session, reason);
      },

      streamDestroyed: (reason) => {
        if (['reset'].indexOf(reason) < 0) {
          // We're back to being a stand-alone publisher again.
          if (!state.isDestroyed()) { state.set('MediaBound'); }
        }

        const event = new Events.StreamEvent('streamDestroyed', this.stream, reason, true);

        this.dispatchEvent(event);
        if (!event.isDefaultPrevented()) {
          this.destroy();
        }
      },

      archivingStatus(status) {
        if (chromeMixin) {
          chromeMixin.setArchivingStatus(status);
        }
        return status;
      },

      webRtcStream() {
        return webRTCStream;
      },

      async switchTracks() {
        let stream;

        try {
          stream = await getUserMedia().catch(userMediaError);
        } catch (err) {
          logging.error(`OT.Publisher.switchTracks failed to getUserMedia: ${err}`);
          throw err;
        }

        setNewStream(stream);

        try {
          bindVideo();
        } catch (err) {
          if (err instanceof CancellationError) {
            return;
          }
          logging.error('Error while binding video', err);
          throw err;
        }

        try {
          replaceTracks();
        } catch (err) {
          logging.error('Error replacing tracks', err);
          throw err;
        }
      },

      getDataChannel(label, getOptions, completion) {
        const pc = getPeerConnectionById(Object.keys(peerConnectionsAsync)[0]);

        // @fixme this will fail if it's called before we have a PublisherPeerConnection.
        // I.e. before we have a subscriber.
        if (!pc) {
          completion(new OTHelpers.Error('Cannot create a DataChannel before there is a subscriber.'));
          return;
        }

        pc.then((peerConnection) => {
          peerConnection.getDataChannel(label, getOptions, completion);
        });
      },

      iceRestart() {
        getAllPeerConnections().then((peerConnections) => {
          peerConnections.forEach((peerConnection) => {
            const { remoteConnectionId } = getPeerConnectionMeta(peerConnection);
            logRepublish('Attempt', { remoteConnectionId });
            logging.debug('Publisher: ice restart attempt');
            peerConnection.iceRestart();
          });
        });
      },

      getState() { return state; },

      demoOnlyCycleVideo: this.cycleVideo,

      async testOnlyGetFramesEncoded() {
        // This is for an integration test only
        // Not robust as it'll only get framesEncoded for the first Peer Connection

        const peerConnections = await getAllPeerConnections();

        if (!peerConnections.length) {
          throw new Error('No established PeerConnections yet');
        }

        return peerConnections[0]._testOnlyGetFramesEncoded();
      },

      onStreamAvailable,

      startRoutedToRelayedTransition: () => {
        logRoutedToRelayedTransition('Attempt');

        const processMessagingError = (error) => {
          const publicError = createStreamErrorMap(error);
          this.trigger('streamCreateForP2PComplete', publicError);
          logRoutedToRelayedTransition('Failure', {
            reason: publicError.message,
          });
        };

        if (!this.session) {
          logRoutedToRelayedTransition('Failure', {
            reason: 'Not connected to the session.',
          });
          return;
        }

        const streamCreateForP2PCompleteTimeout = setTimeout(() => {
          logRoutedToRelayedTransition('Failure', { reason: 'Timeout' });
        }, PUBLISH_MAX_DELAY);

        this.once('streamCreateForP2PComplete', () => {
          clearTimeout(streamCreateForP2PCompleteTimeout);
        });

        logging.debug('streamCreateWithSource: send a message to RUMOR for ' +
          `creating the stream with the sourceStreaId P2P and stream ${this.streamId}`);

        _createStream('P2P', (messagingError) => {
          if (messagingError) {
            processMessagingError(messagingError);
          } else {
            this.trigger('streamCreateForP2PComplete');
          }
        });
      },

      startRelayedToRoutedTransition: async () => {
        logRelayedToRoutedTransition('Attempt');

        if (!this.session) {
          logRelayedToRoutedTransition('Failure', {
            reason: 'Not connected to the session.',
          });
          return;
        }

        if (!this.streamId) {
          logRelayedToRoutedTransition('Failure', {
            reason: 'No streamId available',
          });
          return;
        }

        if (!getP2pPeerConnection()) {
          // If the P2P leg doesn't exist, it means there's no need to transition to routed.
          logRelayedToRoutedTransition('Failure', {
            reason: 'There is no Relayed Peer connection created.',
          });
          return;
        }

        this.session._.streamDestroy(this.streamId, 'P2P');
        this._removePeerConnection(await getP2pPeerConnection());
        await _restartSendingRtpToMantis();
        logRelayedToRoutedTransition('Success');
        this.trigger('streamDestroyForP2PComplete');
      },

      forceMuteAudio: function () {
        logAnalyticsEvent('publishAudio', 'Attempt', { publishAudio: false });
        properties.publishAudio = false;
        try {
          updateAudio('auto');

          this.dispatchEvent(new Events.MuteForcedEvent());

          logAnalyticsEvent('publishAudio', 'Success', { publishAudio: false });
        } catch (e) {
          logAnalyticsEvent('publishAudio', 'Failure', { message: e.message });
        }
      }.bind(this),
    };

    this.detectDevices = function () {
      logging.warn('Publisher.detectDevices() is not implemented.');
    };

    this.detectMicActivity = function () {
      logging.warn('Publisher.detectMicActivity() is not implemented.');
    };

    this.getEchoCancellationMode = function () {
      logging.warn('Publisher.getEchoCancellationMode() is not implemented.');
      return 'fullDuplex';
    };

    this.setMicrophoneGain = function () {
      logging.warn('Publisher.setMicrophoneGain() is not implemented.');
    };

    this.getMicrophoneGain = function () {
      logging.warn('Publisher.getMicrophoneGain() is not implemented.');
      return 0.5;
    };

    this.setCamera = function () {
      logging.warn('Publisher.setCamera() is not implemented.');
    };

    this.setMicrophone = function () {
      logging.warn('Publisher.setMicrophone() is not implemented.');
    };

    // Platform methods:

    this.guid = function () {
      return guid;
    };

    this.videoElement = function () {
      const video = widgetView && widgetView.video();
      return video ? video.domElement() : null;
    };

    this.setStream = assignStream;

    this.isWebRTC = true;

    this.isLoading = function () {
      return widgetView && widgetView.loading();
    };

    /**
    * Returns the width, in pixels, of the Publisher video. This may differ from the
    * <code>resolution</code> property passed in as the <code>properties</code> property
    * the options passed into the <code>OT.initPublisher()</code> method, if the browser
    * does not support the requested resolution.
    *
    * @method #videoWidth
    * @memberOf Publisher
    * @return {Number} the width, in pixels, of the Publisher video.
    */
    this.videoWidth = function () {
      const video = widgetView && widgetView.video();
      return video ? video.videoWidth() : undefined;
    };

    /**
    * Returns the height, in pixels, of the Publisher video. This may differ from the
    * <code>resolution</code> property passed in as the <code>properties</code> property
    * the options passed into the <code>OT.initPublisher()</code> method, if the browser
    * does not support the requested resolution.
    *
    * @method #videoHeight
    * @memberOf Publisher
    * @return {Number} the height, in pixels, of the Publisher video.
    */
    this.videoHeight = function () {
      const video = widgetView && widgetView.video();
      return video ? video.videoHeight() : undefined;
    };

    /**
    *  Returns the details on the publisher's stream quality, including the following:
    *
    * <ul>
    *
    *   <li>The total number of audio and video packets lost</li>
    *   <li>The total number of audio and video packets sent</li>
    *   <li>The total number of audio and video bytes sent</li>
    *   <li>The current video frame rate</li>
    *
    * </ul>
    *
    * You can use these stats to assess the quality of the publisher's audio-video stream.
    *
    * @param {Function} completionHandler A function that takes the following
    * parameters:
    *
    * <ul>
    *
    *   <li><code>error</code> (<a href="Error.html">Error</a>) &mdash; Upon successful completion
    *   the method, this is undefined. An error results if the publisher is not connected to a
    *   session or if it is not publishing audio or video.</li>
    *
    *   <li><code>statsArray</code> (Array) &mdash; An array of objects defining the current
    *   audio-video statistics for the publisher. For a publisher in a routed session (one that
    *   uses the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">OpenTok
    *   Media Router</a>), this array includes one object, defining the statistics for the single
    *   audio-media stream that is sent to the OpenTok Media Router. In a relayed session, the
    *   array includes an object for each subscriber to the published stream. Each object in the
    *   array contains a <code>stats</code> property that includes the following properties:
    *
    *     <p>
    *     <ul>
    *       <li><code>audio.bytesSent</code> (Number) &mdash; The total number of audio bytes
    *         sent to the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>audio.packetsLost</code> (Number) &mdash; The total number audio packets
    *        that did not reach the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>audio.packetsSent</code> (Number) &mdash; The total number of audio
    *        packets sent to the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>timestamp</code> (Number) &mdash; The timestamp, in milliseconds since
    *         the Unix epoch, for when these stats were gathered</li>
    *
    *       <li><code>video.bytesSent</code> (Number) &mdash; The total video bytes sent to
    *         the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>video.packetsLost</code> (Number) &mdash; The total number of video packets
    *         that did not reach the subscriber (or to the OpenTok Media Router)</li>
    *
    *       <li><code>video.packetsSent</code> (Number) &mdash; The total number of video
    *         packets sent to the subscriber</li>
    *
    *       <li><code>video.frameRate</code> (Number) &mdash; The current video frame rate</li>
    *     </ul>
    *
    *     <p>Additionally, for a publisher in a relayed session, each object in the array contains
    *     the following two properties:
    *
    *     <ul>
    *       <li><code>connectionId</code> (String) &mdash; The unique ID of the client's
    *       connection, which matches the <code>id</code> property of the <code>connection</code>
    *       property of the <a href="Session.html#.event:connectionCreated">connectionCreated</a>
    *       event that the Session object dispatched for the remote client.</li>
    *
    *       <li><code>subscriberId</code> (String) &mdash; The unique ID of the subscriber, which
    *       matches the <code>id</code> property of the Subscriber object in the subscribing
    *       client's app.</li>
    *     </ul>
    *
    *     <p>These two properties are undefined for a publisher in a routed session.
    *
    *   </li>
    * </ul>
    *
    * @see <a href="Subscriber.html#getStats">Subscriber.getStats()</a>
    * @see <a href="#getRtcStatsReport">Publisher.getRtcStatsReport()</a>
    *
    * @method #getStats
    * @memberOf Publisher
    */
    this.getStats = function getStats(callback) {
      _getStats((err, stats) => {
        if (err) {
          callback(err);
        } else {
          callback(null, stats);
        }
      });
    };

    /**
    * Returns a promise that, on success, resolves with an array of objects that include
    * RTCStatsReport properties for the published stream. (See
    * <a href="https://developer.mozilla.org/en-US/docs/Web/API/RTCStatsReport" target="_blank">
    * RTCStatsReport</a>.)
    *
    * <p>
    * For a publisher in a routed session (one that uses the
    * <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">OpenTok
    * Media Router</a>), this array includes one object, defining the statistics for the single
    * audio-media stream that is sent to the OpenTok Media Router. In a relayed session, the
    * array includes an object for each subscriber to the published stream. Each object in the
    * array contains an <code>rtcStatsReport</code> property that is a RTCStatsReport object.
    *
    * <p>
    * Additionally, for a publisher in a relayed session, each object in the array contains
    * the following two properties:
    *
    *     <ul>
    *
    *       <li><code>connectionId</code> (String) &mdash; The unique ID of the client's
    *       connection, which matches the <code>id</code> property of the <code>connection</code>
    *       property of the <a href="Session.html#.event:connectionCreated">connectionCreated</a>
    *       event that the Session object dispatched for the remote client.</li>
    *
    *       <li><code>subscriberId</code> (String) &mdash; The unique ID of the subscriber, which
    *       matches the <code>id</code> property of the Subscriber object in the subscribing
    *       client's app.</li>
    *
    *     </ul>
    *
    * <p>
    * These two properties are undefined for a publisher in a routed session.
    *
    * <p>
    * The Promise will be rejected in the following conditions:
    * <ul>
    *   <li>
    *     The browser does not support this method (for example, in Chrome version 57 and lower,
    *     which does not support the <a href="https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats" target="_blank">
    *     RTCPeerConnection.getStats()</a> standard).
    *   </li>
    *   <li>
    *     The <a href="https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection" target="_blank">
    *     PeerConnection</a> for the Publisher is not connected.
    *   </li>
    *  </ul>
    * </p>
    *
    * @method #getRtcStatsReport
    * @memberOf Publisher
    *
    * @see <a href="Subscriber.html#getRtcStatsReport">Subscriber.getRtcStatsReport()</a>
    *
    * @return {Promise} A promise that resolves when the operation completes successfully.
    * If there is an error, the promise is rejected.
    */
    this.getRtcStatsReport = () =>
      new Promise((resolve, reject) => {
        _getRtcStatsReport((err, stats) => {
          if (err) {
            reject(err);
          } else {
            resolve(stats);
          }
        });
      });

    // Make read-only: element, guid, _.webRtcStream

    state = new PublishingState(stateChangeFailed);

    this.accessAllowed = false;

    /**
     * Applies a video filter for the publisher. You can apply a background blur filter.  A
     * publisher can have only one (or zero) applied at one time.  When you set a new
     * filter, a previously set filter is removed.
     *
     * <p>
     * This is a <em>beta</em> feature.
     *
     * <p>
     * Calling this method results in an error (the Promise returned by the method is rejected)
     * in the following conditions:
     * <ul>
     *   <li>
     *     The browser does not support this method.
     *   </li>
     *   <li>
     *     The publisher is not using a camera video source. (The <code>videoSource</code>
     *     option of the <a href="OT.html#initPublisher">OT.initPublisher()</a> method was
     *     set to <code>null</code>, <code>false</code>, a MediaStreamTrack object, or
     *     <code>"screen"</code>).
     *   </li>
     *   <li>
     *     There are no video input devices (cameras) available.
     *   </li>
     *   <li>
     *     There was an error acquiring video from the video input device.
     *   </li>
     *   <li>
     *     There was an error applying the transformation filter.
     *   </li>
     *  </ul>
     * </p>
     *
     * <p>
     * You can also set the initial video filter by setting a <code>videoFilter</code> option
     * when calling <a href="OT.html#initPublisher">OT.initPublisher()</a>.
     *
     * @param {Object} videoFilter An object defining the video filter to be applied. Set the
     * <code>type</code> property of the object to <code>"backgroundBlur"</code> (for a background
     * blur filter, the only filter supported in this beta version of the video filter feature).
     * A background blur filter object includes an additional property <code>blurStrength</code>
     * property, which defines the blur radius and is optional. Set this to either <code>"low"</code>
     * or <code>"high"</code> (the default).
     *
     * @method #applyVideoFilter
     * @memberOf Publisher
     *
     * @see <a href="Publisher.html#getVideoFilter">Publisher.getVideoFilter()</a>
     * @see <a href="Publisher.html#clearVideoFilter">Publisher.clearVideoFilter()</a>
     *
     * @return {Promise} A promise that resolves when the operation completes successfully.
     * If there is an error, the promise is rejected and no new video filter is set.
     */
    this.applyVideoFilter = async (videoFilter) => {
      logAnalyticsEvent('applyVideoFilter', 'Attempt', { videoFilter });

      try {
        const isSupported = MediaProcessor.isSupported();

        if (!isSupported) {
          throw otError(
            Errors.NOT_SUPPORTED,
            new Error('Browser does not support video filters (Insertable Streams and Worker APIs are required)')
          );
        }

        if (!mediaProcessor.isValidVideoFilter(videoFilter)) {
          throw otError(
            Errors.INVALID_PARAMETER,
            new Error('Video filter has invalid configuration')
          );
        }

        if (!webRTCStream) {
          const message = 'Ignoring. No mediaStream';
          logAnalyticsEvent('applyVideoFilter', 'Failure', { message });
          logging.warn(message);
          return;
        }

        if (isScreenSharing || isCustomVideoTrack) {
          throw otError(
            Errors.INVALID_PARAMETER,
            new Error('Video filters can not be applied to screen shares or custom video sources')
          );
        }

        // Note: until mediaProcessor lets us change configuration at runtime
        // (e.g., from blur high to blur low), or if we refactor to support multiple
        // MediaProcessors to mitigate, video will always momentarily be unfiltered
        // when switching filters.
        if (this.getVideoFilter()) {
          await this.clearVideoFilter();
        }

        const [originalVideoTrack] = webRTCStream.getVideoTracks();

        if (!originalVideoTrack) {
          const message = 'Ignoring. No video';
          logAnalyticsEvent('applyVideoFilter', 'Failure', { message });
          logging.warn(message);
          return;
        }

        await mediaProcessor.setVideoFilter(videoFilter);
        const filteredVideoTrack = await mediaProcessor.setMediaStream(webRTCStream);

        if (filteredVideoTrack) {
          await replaceTrackAndUpdate(originalVideoTrack, filteredVideoTrack);
        }
      } catch (err) {
        logging.error(`Error applying video filter: ${err}`);
        logAnalyticsEvent('applyVideoFilter', 'Failure', { message: err.message });
        throw err;
      }

      currentVideoFilter = videoFilter;
      logAnalyticsEvent('applyVideoFilter', 'Success', { videoFilter });
    };

    /**
     * Returns the video filter applied used by the publisher.
     *
     * <p>
     * This is a <em>beta</em> feature.
     *
     * @method #getVideoFilter
     * @memberOf Publisher
     *
     * @see <a href="Publisher.html#applyVideoFilter">Publisher.applyVideoFilter()</a>
     * @see <a href="Publisher.html#clearVideoFilter">Publisher.clearVideoFilter()</a>
     *
     * @return {Object} The video filter being applied to the video input source used by the publisher.
     * Check the <code>type</code> property of the object to see the video filter type.
     * <code>"backgroundBlur"</code> is the only filter type supported in this beta version of
     * the video filter feature). A background blur filter object has a <code>blurStrength</code>
     * property, which defines the blur radius and is set to either <code>"low"</code> or
     * <code>"high"</code>. The method returns <code>undefined</code> if there is no video filter.
     */
    this.getVideoFilter = () => {
      logAnalyticsEvent('getVideoFilter', 'Attempt');

      const videoFilter = mediaProcessor.getVideoFilter();

      logAnalyticsEvent('getVideoFilter', 'Success');

      return videoFilter;
    };

    /**
     * Removes the video filter being applied to the publisher.
     *
     * <p>
     * This is a <em>beta</em> feature.
     *
     * <p>
     * This will result in an error (the Promise returned by the method is rejected) if the
     * video filter could not be stopped.
     *
     * @method #clearVideoFilter
     * @memberOf Publisher
     *
     * @see <a href="Publisher.html#applyVideoFilter">Publisher.applyVideoFilter()</a>
     * @see <a href="Publisher.html#getVideoFilter">Publisher.getVideoFilter()</a>
     *
     * @return {Promise} A promise that resolves when the operation completes successfully.
     * If there is an error, the promise is rejected.
     */
    this.clearVideoFilter = async () => {
      logAnalyticsEvent('clearVideoFilter', 'Attempt');

      if (!this.getVideoFilter()) {
        const message = 'Ignoring. No video filter applied';
        logAnalyticsEvent('clearVideoFilter', 'Success', { message });
        logging.debug(message);
        return;
      }

      if (!MediaProcessor.isSupported()) {
        const message = 'Ignoring. "clearVideoFilter" not supported.';
        logAnalyticsEvent('clearVideoFilter', 'Success', { message });
        logging.warn(message);
        return;
      }

      if (!webRTCStream) {
        const message = 'Ignoring. No mediaStream';
        logAnalyticsEvent('clearVideoFilter', 'Success', { message });
        logging.warn(message);
        return;
      }

      if (currentVideoFilter) {
        const [filteredVideoTrack] = webRTCStream.getVideoTracks();
        const videoTrack = await getTrackFromDeviceId(currentDeviceId);

        await replaceTrackAndUpdate(filteredVideoTrack, videoTrack);
        await destroyMediaProcessor();

        currentVideoFilter = null;
      }
    };
  };

  /**
  * Dispatched when the user has clicked the Allow button, granting the
  * app access to the camera and microphone. The Publisher object has an
  * <code>accessAllowed</code> property which indicates whether the user
  * has granted access to the camera and microphone.
  * @see Event
  * @name accessAllowed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the user has clicked the Deny button, preventing the
  * app from having access to the camera and microphone.
  * <p>
  * <i>Note:</i> On macOS 10.15+ (Catalina), to publish a screen-sharing stream
  * the user must grant the browser access to the screen in macOS System Preferences &gt;
  * Security &amp; Privacy &gt; Privacy &gt; Screen Recording. Otherwise,
  * the Publisher will dispatch an <code>accessDenied</code> event.
  *
  * @see Event
  * @name accessDenied
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny dialog box is opened. (This is the dialog box in which
  * the user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogOpened
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny box is closed. (This is the dialog box in which the
  * user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogClosed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched periodically to indicate the publisher's audio level. The event is dispatched
  * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
  * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
  * information.
  * <p>
  * The following example adjusts the value of a meter element that shows volume of the
  * publisher. Note that the audio level is adjusted logarithmically and a moving average
  * is applied:
  * <p>
  * <pre>
  * var movingAvg = null;
  * publisher.on('audioLevelUpdated', function(event) {
  *   if (movingAvg === null || movingAvg &lt;= event.audioLevel) {
  *     movingAvg = event.audioLevel;
  *   } else {
  *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
  *   }
  *
  *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
  *   logLevel = Math.min(Math.max(logLevel, 0), 1);
  *   document.getElementById('publisherMeter').value = logLevel;
  * });
  * </pre>
  * <p>This example shows the algorithm used by the default audio level indicator displayed
  * in an audio-only Publisher.
  *
  * @name audioLevelUpdated
  * @event
  * @memberof Publisher
  * @see AudioLevelUpdatedEvent
  */

  /**
   * The publisher has started streaming to the session.
   * @name streamCreated
   * @event
   * @memberof Publisher
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * The publisher has stopped streaming to the session. The default behavior is that
   * the Publisher object is removed from the HTML DOM. The Publisher object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method of the event object in the event listener, the default
   * behavior is prevented, and you can, optionally, retain the Publisher for reuse or clean it up
   * using your own code.
   * @name streamDestroyed
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */

  /**
  * Dispatched when the Publisher element is removed from the HTML DOM. When this event
  * is dispatched, you may choose to adjust or remove HTML DOM elements related to the publisher.
  * @name destroyed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the video dimensions of the video change. This can only occur in when the
  * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
  * video stream), when the user resizes the window being captured. This event object has a
  * <code>newValue</code> property and an <code>oldValue</code> property, representing the new and
  * old dimensions of the video. Each of these has a <code>height</code> property and a
  * <code>width</code> property, representing the height and width, in pixels.
  * @name videoDimensionsChanged
  * @event
  * @memberof Publisher
  * @see VideoDimensionsChangedEvent
*/

  /**
  * Dispatched when the Publisher's video element is created. Add a listener for this event when
  * you set the <code>insertDefaultUI</code> option to <code>false</code> in the call to the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method. The <code>element</code>
  * property of the event object is a reference to the Publisher's <code>video</code> element
  * (or in Internet Explorer the <code>object</code> element containing the video). Add it to
  * the HTML DOM to display the video. When you set the <code>insertDefaultUI</code> option to
  * <code>false</code>, the <code>video</code> (or <code>object</code>) element is not
  * automatically inserted into the DOM.
  * <p>
  * Add a listener for this event only if you have set the <code>insertDefaultUI</code> option to
  * <code>false</code>. If you have not set <code>insertDefaultUI</code> option to
  * <code>false</code>, do not move the <code>video</code> (or <code>object</code>) element in
  * in the HTML DOM. Doing so causes the Publisher object to be destroyed.
  *
  * @name videoElementCreated
  * @event
  * @memberof Publisher
  * @see VideoElementCreatedEvent
  */

  /**
   * A moderator has forced this client to mute audio.
   *
   * @name muteForced
   * @event
   * @memberof Publisher
   * @see Event
   */

  /**
   * The user publishing the stream has stopped sharing one or all media
   * types (video, audio and/or screen). This can occur when a user disconnects a camera or
   * microphone used as a media source for the Publisher. Or it can occur when a user closes
   * a when the video and audio sources of the stream are MediaStreamTrack elements and
   * tracks are stopped or destroyed.
   *
   * @name mediaStopped
   * @event
   * @memberof Publisher
   * @see MediaStoppedEvent
   */
  return Publisher;
};
