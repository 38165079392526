import { useState, useEffect } from 'react'
import axios from 'axios';

const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export const saveAuthCookie = (name, result) => {
  let today = new Date();
  let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // 24 hours
  document.cookie = `${name}=${result}; expires=${tomorrow}; path=/; SameSite=None; Secure`;
}

const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(getCookie("divanMedToken"))
  const [error, setError] = useState(null)

  useEffect(() => {
    if (token && !user) {
      let callOptions = {headers: {'Authorization': `Token ${token}`}}
      axios.get(`${process.env.REACT_APP_API_URL}/auth/user`, callOptions)
        .then(response => setUser(response.data) )
        .catch(error => { setError('Something went wrong'); if(error.response?.status === 401){ sessionStorage.clear(); setUser(null); setToken(null); setError('Credentials expired. Please log in.'); } } )
    }
  }, [token, user]);

  const saveCookie = (authorization) =>{
    saveAuthCookie("divanMedToken", authorization)
    setToken(authorization)
  }

  const expireCookie = () =>{
    let today = new Date();
    let yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    document.cookie = `divanMedToken=${token}; expires=${yesterday}; path=/`;
    setUser(null)
  }

  const signin = (userData, cb) => {
    setError(null)
    axios.post(`${process.env.REACT_APP_API_URL}/sessions`, userData)
          .then( resp => { saveCookie(resp.headers.authorization); return(resp.data)})
          .then(response => { 
            setUser(response);
            cb();
          })
          .catch(error => { setError("Invalid credentials. Please try again.") } );
    
  };

  const signout = (cb) => {
    expireCookie();
    setUser(null);
    setToken(null);
    cb();
  };

  return {
    user,
    signin,
    signout,
    error,
    token,
    setUser
  };
}

export default useProvideAuth