// Some Safari users can't establish a call using server reflexive candidates
// * https://bugs.webkit.org/show_bug.cgi?id=233752
// * https://jira.vonage.com/browse/OPENTOK-45642
module.exports = (env) => {
  // eslint-disable-next-line global-require, no-param-reassign
  env = env || require('./env');

  if (env.isiPad) {
    // UA varies in iPad depending on Desktop/Mobile site preferences. So we could have the
    // following scenarios:
    // - Safari iPad using mobile preferred.
    // - Safari iPad using desktop preferred.
    // - Webkit browser (e.g. Chrome|Firefox) using mobile preferred.
    // - Webkit browser (e.g. Chrome|Firefox) using desktop preferred.
    // When desktop preferred, we should check for the Safari version, otherwise check the
    // iOS version. However we do not have a Safari version for Chrome iPad using desktop
    // agent. Thus we will always force TURN in iPad to avoid an overcomplex logic
    return true;
  }

  // All versions of iOS and macOS 15+ are impacted by this issue
  const impactedVersion = 15;

  if (env.isiPhone) {
    return env.iOSVersion >= impactedVersion;
  }

  if (env.isSafari) {
    return env.version >= impactedVersion;
  }

  return false;
};
