import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

export default function NotesList({notesArr, deleteNote}) {
  const notesItems = notesArr.map((note) =>
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
      key={note.id}
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">{note.content}</div>
        By: {note.created_by} ({note.created_at})
      </div>
      <Badge bg="danger" pill onClick={() => deleteNote(note.id)}>
        X
      </Badge>
    </ListGroup.Item>
  );

  return(
    <div>
      <h2>Notes</h2>
      {notesItems.length === 0 && <div>No notes available</div>}
      <ListGroup as="ul" numbered>
        {notesItems}
      </ListGroup>
    </div>
    
  );
}