const env = require('../helpers/env');

let sframe = { SFrame: {} };
// SFrame lib uses EventTarget that is not available in Node.js, which makes travis to fail.
// As a workaround, we only require the sframe lib if the environment is not Node.
if (!env.isNode) {
  // eslint-disable-next-line global-require
  sframe = require('sframe');
}
const { SFrame } = sframe;
const clientStore = {};
const getSender = senderId => clientStore[senderId]?.sender;
const getReceiver = senderId => clientStore[senderId]?.receiver;
const createClient = senderId => SFrame.createClient(senderId, { skipVp8PayloadHeader: true });

const sFrameClientStore = {
  createSender: async (senderId = 0) => {
    if (getSender(senderId)) {
      return getSender(senderId);
    }

    if (!clientStore[senderId]) {
      clientStore[senderId] = {};
    }

    clientStore[senderId].sender = await createClient(senderId);

    return getSender(senderId);
  },
  createReceiver: async (senderId) => {
    if (getReceiver(senderId)) {
      return getReceiver(senderId);
    }

    if (!clientStore[senderId]) {
      clientStore[senderId] = {};
    }

    clientStore[senderId].receiver = await createClient(senderId);

    return getReceiver(senderId);
  },
  getSender,
  getReceiver,
  deleteReceiver: (senderId) => {
    if (getReceiver(senderId)) {
      clientStore[senderId].receiver.deleteReceiver(senderId);
      delete clientStore[senderId].receiver;
    }
  },
  deleteSender: senderId =>
    delete clientStore[senderId]?.sender
  ,
};

module.exports = sFrameClientStore;
