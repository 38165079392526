import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { ALL_STATES } from '../util';

export default function CreateProfessionalLicenseForm({createProfessionalLicense, setShowLicenseForm}) {
  const [code, setCode] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [issueDate, setIssueDate] = useState();
  const [licenseType, setLicenseType] = useState();
  const [stateId, setStateId] = useState();

  const licenseTypeOptions = ["CSW", "DO", "LC", "LCPC", "LCSW", "LH", "LICSW", "LMFT", "LMHC", "LMSW", "LPC", "LPCC", "MD", "ME", "PC", "PhD"]

  const handleSubmit = async e => {
    e.preventDefault();
    createProfessionalLicense({code, expirationDate, issueDate, licenseType, stateId});
    e.target.reset();
  }

  return(
    <div className="my-3">
      <h3>Add Professional License</h3>
      <form onSubmit={handleSubmit} className="form-control">
        <label>Type</label>
        <Form.Select aria-label="Default select example" onChange={e => setLicenseType(e.target.value)} className="form-floating mb-3">
          <option>Select One</option>
          {licenseTypeOptions.map( lo => <option value={lo} key={lo}>{lo}</option>)}
        </Form.Select>
        <label>License State</label>
        <Form.Select aria-label="Default select example" onChange={e => setStateId(e.target.value)} className="form-floating mb-3">
          <option>Select One</option>
          {ALL_STATES.map( state => <option value={state.id} key={state.id + state.iso_abbr}>{state.name}</option>)}
        </Form.Select>
        <div className="form-floating mb-3">
          <input type="text" htmlFor="code" name="code" className="form-control" onChange={e => setCode(e.target.value)} />
          <label>License Code</label>
        </div>
        <div className="form-floating mb-3">
          <input type="date" htmlFor="issueDate" name="issueDate" className="form-control" onChange={e => setIssueDate(e.target.value)} />
          <label>Issue Date</label>
        </div>
        <div className="form-floating mb-3">
          <input type="date" htmlFor="expirationDate" name="expirationDate" className="form-control" onChange={e => setExpirationDate(e.target.value)} />
          <label>Expiration Date</label>
        </div>
        <div>
          <button onClick={()=> setShowLicenseForm(false)} className="btn btn-link">Cancel</button>
          <button type="submit" className="btn btn-outline-primary">Add Professional License</button>

        </div>
      </form>
    </div>
  )
}

CreateProfessionalLicenseForm.propTypes = {
  createProfessionalLicense: PropTypes.func.isRequired
};